const VideoQuality = require('./video_quality');
const EventEmitter = require('events').EventEmitter;
const Detect = require('../platform/detect');
const Utils = require('./utils');
const PacketLoss = require('./packet_loss');
const FrameDelayRate = require('./frame_delay_rate');
const FrameAverage = require('./frame_average');
const RTCatLog  = require('./log');
const Config = {
  GENERATE_LOG_PERIOD: 1,
  DYNAMIC_NUMBER: 10,
};


class WebRTCStatsParser extends EventEmitter {

  constructor(type,options) {
    super();
    this.type = type;
    this.video = {
      // // subscriber
      // kBytesReceived,
      // kBpsReceived,
      // currentDelayMs,
      // decodeMs,
      // codecName,
      // fps,
      // framesDecoded,
      // height,
      // width,
      // jitter,
      // packetsReceived,
      // packetsLost,
      // packetsLossRate,
      // interframeDelayMax
      // mos

      // // publisher
      // kBpsSent,
      // kBytesSent,
      // fps,
      // codecName,
      // encodeMs,
      // fpsInput,
      // packetsSent,
      // packetsLost,
      // packetsLossRate,
      // height,
      // width,
      // rtt,
      // mos
    };
    this.audio = {
      // // subscriber
      // kBpsReceived,
      // kBytesReceived,
      // currentDelayMs,
      // codecName,
      // volumeLevel,
      // jitter,
      // packetsLost,
      // packetsReceived,
      // packetsLossRate

      // // publisher
      // kBpsSent,
      // kBytesSent,
      // codecName,
      // packetsLost,
      // packetsSent,
      // packetsLossRate
    };
    this.net = {
      // local_ip,
      // local_candidate_type,
      // transport_protocol,
      // remote_ip,
      // remote_candidate_type,
      // networkType,
      // rtt,

      // // publisher
      // kBpsSent,
      // kBytesSent

      // // subscriber
      // kBpsReceived,
      // kBytesReceived
    };
    this.videoBwe = {
      // arb,
      // asb
    };

    this.videoDetecter = new VideoQuality(type);

    this.generateTimer = null;
    this.isStart = false;

    this.hasVideo = false;
    this.hasAudio = false;

    this.timestamp = 0;

    this.videoPLRProcesser = new PacketLoss(options,type,"video");
    this.audioPLRProcesser = new PacketLoss(options,type,"audio");
    this.videoFrameDelay = new FrameDelayRate(options,type);
    this.frameAverage = new FrameAverage(options,type);

    this.index = 0;
    // this.statsList = [];
  }

  start() {
    if (!this.isStart) {
      this.isStart = true;
      this.addLogTimer();
    }
  }

  isEmiter() {
    let emiter = false;
    let model = this.index%2;
    this.index = model + 1;
    if(model === 1) {
      emiter = true;
      return emiter;
    }
    return emiter;
  }

  addLogTimer() {
    this.generateTimer = setInterval(() => {
      this.emit('get-stats');
    }, 1000 * Config.GENERATE_LOG_PERIOD);
  }

  addStats(stats,sendvideo,sendaudio) {
    let r = nativeStatsToMap(stats);

    this.parseStats(r,sendvideo,sendaudio);

    let s = splitStaticDynamicStats(r);

    let dynamicStats = Utils.mapToJSON(s[1]);

    this.updateDynamicStats(dynamicStats);
  }

  addAdapterStats(stats) {
    let newStats = {};

    this.parseStatsMoz(stats);

    stats.forEach(r => {
      if ((r.type == "inbound-rtp") ||
        (r.type == "outbound-rtp") ||
        (r.type == "candidate-pair")) {
        if (Detect.isSafari() || Detect.isMoz()) {
          if (r.type == "inbound-rtp" && r.isRemote == false) {
            if (r.id.indexOf("video") != -1) {
              newStats[r.type + "-video"] = r;
            } else if (r.id.indexOf("audio") != -1) {
              newStats[r.type + "-audio"] = r;
            }
          } else if (r.type == "candidate-pair" && r.selected == true) {
            newStats[r.type] = r;
          }
        } else {
          newStats[r.type] = r;
        }
      }
    });
  }

  parseStats(stats,sendvideo,sendaudio) {
    let report = false;
    let isEven = this.isEmiter();
    //var d = new Date();
    //var hour= d.getHours();
    //var minute= d.getMinutes();
    //var second= d.getSeconds();
    //console.log("%s-%s-%s",hour,minute,second)
    //console.log(stats);
    for (let [key, res] of stats) {
      //网络状态
      if (res.type == "googCandidatePair" && res.googActiveConnection == 'true' && res.googReadable == 'true') {
        // console.log(res);
        this.timestamp = res.timestamp.getTime();
        this.fetchNetState(res);
      }
      //视频带宽
      if (res.type == 'VideoBwe') {
        this.fetchVideoBwe(res);
      }

      if (res.type == 'localcandidate') {
        this.net.networkType = res.networkType;
      }

      if (res.type == "ssrc") {
        //console.log(res);
        if (res.mediaType == "video") {
          this.fetchVideoState(res);
          if(sendvideo === true && isEven && !report && this.type == "subscriber") {
            report = this.emitVideoLags("subscriber","frame_average",res.id);
            if(!report) {
              report = this.emitVideoLags("subscriber","loss_rate",res.id);
            }
          } else if(sendvideo === true && isEven && !report && this.type == "publisher") {
            report = this.emitVideoLags("publisher","frame_average",res.id);
            if(!report) {
              report = this.emitVideoLags("publisher","loss_rate",res.id);
            }
          }
        }

        if (res.mediaType == "audio") {
          if ((res.hasOwnProperty('bytesSent') && this.type == 'publisher') ||
            (res.hasOwnProperty('bytesReceived') && this.type == 'subscriber')) {
            this.fetchAudioState(res);
            if(sendaudio === true && isEven && !report && this.type == "subscriber") {
              report = this.emitAudioLags("subscriber","loss_rate",res.id);
            } else if (sendaudio === true && isEven && !report && this.type == "publisher") {
              report = this.emitAudioLags("publisher","loss_rate",res.id);
            }
          }
        }
      }
    }
    this.emitParsedStats();
  }

  parseStatsMoz(results) {
    let localCandidateId;
    let remoteCandidateId;

    results.forEach(res => {
      if (this.type == 'publisher') {
        if (res.type == 'outbound-rtp' && (res.mediaType == "video" || res.id.toLowerCase().indexOf("video") !== -1)) {
          // console.log(this.type,res);
          if (!res.isRemote) {
            this.fetchVideoState(res);
          }

        }

        if (res.type == 'outbound-rtp' && (res.mediaType == "audio" || res.id.toLowerCase().indexOf("audio") !== -1)) {
          // console.log(this.type,res);
          if (!res.isRemote) {
            this.fetchAudioState(res);
          }
        }
      }

      if (this.type == 'subscriber') {
        if (res.type == 'inbound-rtp' && (res.mediaType == "video" || res.id.toLowerCase().indexOf("video") !== -1)) {
          // console.log(this.type,res);
          if (!res.isRemote) {
            this.fetchVideoState(res);
          }

        }

        if (res.type == 'inbound-rtp' && (res.mediaType == "audio" || res.id.toLowerCase().indexOf("audio") !== -1)) {
          // console.log(this.type,res);
          if (!res.isRemote) {
            this.fetchAudioState(res);
          }
        }
      }

      if (res.type == 'candidate-pair' && res.selected == true && res.localCandidateId && res.remoteCandidateId) {
        localCandidateId = res.localCandidateId;
        remoteCandidateId = res.remoteCandidateId;
      }
    });

    results.forEach(res => {
      if (res.type == 'local-candidate' && res.id == localCandidateId) {
        this.net.local_ip = res.ipAddress;
        this.net.local_candidate_type = res.candidateType;
        this.net.transport_protocol = res.transport;
      }
      //
      if (res.type == 'remote-candidate' && res.id == remoteCandidateId) {
        this.net.remote_ip = res.ipAddress;
        this.net.remote_candidate_type = res.candidateType;
      }
    });

    this.emitParsedStats();
  }

  fetchNetState(res) {

    if (this.type == 'publisher') {
      let originBytesSent = toDecimal(res.bytesSent / 1024);
      this.net.kBpsSent = toDecimal((originBytesSent - (this.net.kBytesSent || 0)) / Config.GENERATE_LOG_PERIOD);
      this.net.kBpsSent = this.net.kBpsSent > 0 ? this.net.kBpsSent : 0;
      this.net.kBytesSent = originBytesSent;
    }

    if (this.type == 'subscriber') {
      let originBytesReceived = toDecimal(res.bytesReceived / 1024);
      this.net.kBpsReceived = toDecimal((originBytesReceived - (this.net.kBytesReceived || 0)) / Config.GENERATE_LOG_PERIOD);
      this.net.kBpsReceived = this.net.kBpsReceived > 0 ? this.net.kBpsReceived : 0;
      this.net.kBytesReceived = originBytesReceived;
    }


    if (res.googLocalAddress) {
      this.net.local_ip = res.googLocalAddress;
    }

    if (res.googRemoteAddress) {
      this.net.remote_ip = res.googRemoteAddress;
    }

    if (res.googLocalCandidateType) {
      this.net.local_candidate_type = res.googLocalCandidateType; //
    }

    if (res.googRemoteCandidateType) {
      this.net.remote_candidate_type = res.googRemoteCandidateType; // http://bluejimp.com/jitsi/api/net/java/sip/communicator/impl/protocol/jabber/extensions/jingle/CandidateType.html
    }

    if (res.googTransportType) {
      this.net.transport_protocol = res.googTransportType; //udp
    }

    if (res.googRtt) {
      this.net.rtt = res.googRtt; // unit ms
    }
  }

  fetchVideoBwe(res) {
    if (this.type == 'subscriber') {
      if (res.googAvailableReceiveBandwidth) {
        this.videoBwe.arb = res.googAvailableReceiveBandwidth;
      }
    }

    if (this.type == 'publisher') {
      if (res.googAvailableSendBandwidth) {
        this.videoBwe.asb = res.googAvailableSendBandwidth;
      }
    }

  }

  emitVideoLags(roleType,emitType,id) {
      let result = {};
      let needEmit = false;
      let value;
      switch(emitType) {
        case "loss_rate":
          this.video.packetsLossRate = this.videoPLRProcesser.getPacketLossRate();
          result = this.videoPLRProcesser.judgeLags(this.video.packetsLossRate,"video");
          result["media_type"] = "video";
          result["role"] = roleType;
          //console.log("key:loss_rate,result:%o",result);
          break;
        case "frame_average":
          result = this.frameAverage.judgeLags(roleType);
          result["role"] = roleType;
          //console.log("key:frame_average,result:%o",result);
          break;
        case "frame_delay":
          result = this.videoFrameDelay.judgeLags();
          break;
      }

      if(result.isOverLoad) {
        value = result.avgValue;
        needEmit = true;
      }

      if(needEmit) {

        let stats = {
          "media":"video",
          "user" : roleType,
          "id" : id,
          "lags_key" : emitType,
          "lags_value": value
        };
        //console.log('flency report video');
        this.emit('flency-report', stats);
        return true;
      }
      return false;
    }

  emitAudioLags(roleType,emitType,id) {
    let needEmit = false;
    let value;
    let result = {};
    this.audio.packetsLossRate = this.audioPLRProcesser.getPacketLossRate();
    result = this.audioPLRProcesser.judgeLags(this.audio.packetsLossRate,"audio");
    result["media_type"] = "audio";
    result["role"] = roleType;
    //console.log("key:loss_rate,result:%o",result);
    if(result.isOverLoad) {
      needEmit = true;
      value = this.audio.packetsLossRate;
    }

    if(needEmit) {
      let stats = {
        "media":"audio",
        "user" : roleType,
        "id" : id,
        "lags_key" : emitType,
        "lags_value": value
      };
      //console.log('flency report audio');
      this.emit('flency-report', stats);
      return true;
    }
    return false;
  }

  fetchVideoState(res) {
    this.hasVideo = true;
    if (this.type == 'subscriber') {
      const currentReceived = toDecimal(res.bytesReceived / 1024);
      this.video.kBpsReceived = toDecimal((currentReceived - (this.video.kBytesReceived || 0)) / Config.GENERATE_LOG_PERIOD);
      this.video.kBytesReceived = currentReceived;

      if (res.googCurrentDelayMs) {
        this.video.currentDelayMs = res.googCurrentDelayMs;
      }

      if (res.googDecodeMs) {
        this.video.decodeMs = res.googDecodeMs;
      }

      if (res.googCodecName) {
        this.video.codecName = res.googCodecName;
      }

      if (res.googFrameRateDecoded) {
        this.video.fps = res.googFrameRateDecoded;
      } else if (res.framerateMean) {
        this.video.fps = res.framerateMean;
      } else if (res.framesDecoded) {
        this.video.fps = res.framesDecoded - (this.video.framesDecoded || 0);
        this.video.framesDecoded = res.framesDecoded;
      }

      if (res.googFrameHeightReceived) {
        this.video.height = res.googFrameHeightReceived;
      }

      if (res.googFrameWidthReceived) {
        this.video.width = res.googFrameWidthReceived;
      }

      if (res.googJitterBufferMs) {
        this.video.jitter = res.googJitterBufferMs;
      } else if (res.jitter) {
        this.video.jitter = res.jitter;
      }

      if (res.googInterframeDelayMax) {
        this.video.interframeDelayMax = res.googInterframeDelayMax;
        //this.videoFrameDelay.addFrameDelay(res.googInterframeDelayMax);
        //isReport = this.emitVideoLags("subscriber","frame_delay",res.id);
      }

      if (res.packetsLost && res.packetsReceived) {
        this.video.packetsReceived = res.packetsReceived;
        this.video.packetsLost = res.packetsLost;
        this.videoPLRProcesser.addData(this.video.packetsReceived, this.video.packetsLost);
      }

      if (this.video.fps) {
        this.videoDetecter.addVideoInfo(null, null, null, null, this.video.fps);
        this.video.mos = this.videoDetecter.getSubjectQuality();
        this.frameAverage.addSecondFps(this.video.fps);
      }

    }

    if (this.type == 'publisher') {
      const currentSent = toDecimal(res.bytesSent / 1024);
      this.video.kBpsSent = toDecimal((currentSent - (this.video.kBytesSent || 0)) / Config.GENERATE_LOG_PERIOD);
      this.video.kBytesSent = currentSent;

      if (res.googFrameRateSent) {
        this.video.fps = res.googFrameRateSent;
      }

      if (res.googCodecName) {
        this.video.codecName = res.googCodecName;
      }

      if (res.googAvgEncodeMs) {
        this.video.encodeMs = res.googAvgEncodeMs;
      }

      if (res.googFrameRateInput) {
        this.video.fpsInput = res.googFrameRateInput;
        this.videoDetecter.setStandardFps(res.googFrameRateInput);
      }

      if (res.googRtt) {
        this.video.rtt = res.rtt;
      }

      if (res.googFrameHeightSent) {
        this.video.height = res.googFrameHeightSent;
      }

      if (res.googFrameWidthSent) {
        this.video.width = res.googFrameWidthSent;
      }

      if (this.video.fps) {
        this.videoDetecter.addVideoInfo(null, null, null, null, this.video.fps, this.video.rtt);
        this.video.mos = this.videoDetecter.getSubjectQuality();
        this.videoDetecter.getRttQuality();
        this.frameAverage.addSecondFps(this.video.fps);
      }

      if (res.packetsLost && res.packetsSent) {
        this.video.packetsSent = res.packetsSent;
        this.video.packetsLost = res.packetsLost;
        this.videoPLRProcesser.addData(this.video.packetsSent, this.video.packetsLost);
      }
    }
  }

  fetchAudioState(res) {
    this.hasAudio = true;
    if (this.type == 'subscriber') {
      const currentReceived = toDecimal(res.bytesReceived / 1024);

      this.audio.kBpsReceived = toDecimal((currentReceived - (this.audio.kBytesReceived || 0)) / Config.GENERATE_LOG_PERIOD);
      this.audio.kBytesReceived = currentReceived;

      if (res.googCurrentDelayMs) {
        this.audio.currentDelayMs = res.googCurrentDelayMs;
      }

      if (res.googCodecName) {
        this.audio.codecName = res.googCodecName;
      }

      if (res.audioOutputLevel) {
        this.audio.volumeLevel = res.audioOutputLevel;
      }

      if (res.googJitterBufferMs) {
        this.audio.jitter = res.googJitterBufferMs;
      } else if (res.jitter) {
        this.audio.jitter = res.jitter;
      }


      if (res.packetsReceived && res.packetsLost) {
        this.audio.packetsLost = res.packetsLost;
        this.audio.packetsReceived = res.packetsReceived;
        if(this.audio.packetsReceived>1000000) {
          console.log("sub %s",this.audio.packetsReceived);
        }
        this.audioPLRProcesser.addData(this.audio.packetsReceived, this.audio.packetsLost);
      }
    }

    if (this.type == 'publisher') {
      const currentSent = toDecimal(res.bytesSent / 1024);

      this.audio.kBpsSent = toDecimal((currentSent - (this.audio.kBytesSent || 0)) / Config.GENERATE_LOG_PERIOD);
      this.audio.kBytesSent = currentSent;

      if (res.googCodecName) {
        this.audio.codecName = res.googCodecName;
      }

      if (res.packetsSent && res.packetsLost) {
        this.audio.packetsLost = res.packetsLost;
        this.audio.packetsSent = res.packetsSent;
        if(this.audio.packetsSent>1000000) {
          console.log("pub %s",this.audio.packetsSent);
        }
        this.audioPLRProcesser.addData(this.audio.packetsSent, this.audio.packetsLost);
      }
    }
  }


  emitParsedStats() {
    this.emit('parsed-stats', {
      net: this.net,
      videoBwe: this.videoBwe,
      video: this.video,
      audio: this.audio,
      timestamp: this.timestamp,
    });
  }

  updateDynamicStats(stats) {
    // this.statsList.push(stats);

    // if(this.statsList.length >= Config.DYNAMIC_NUMBER){
    //     this.onDynamicStats(this.statsList);
    //     this.statsList = [];
    // }

    let newStats = {};
    for (let key in stats) {
      if (stats.hasOwnProperty(key)) {
        let l = stats[key];
        switch (l.type) {
          case "googCandidatePair": {
            newStats["Conn"] = l;
            break;
          }
          case "VideoBwe": {
            newStats["bweforvideo"] = l;
            break;
          }
          case "ssrc": {
            newStats["ssrc_" + l["mediaType"]] = l;
            break;
          }
        }
      }
    }
    this.onDynamicStats(newStats);
  }

  onDynamicStats(stats) {
    // console.log("dynamic stats",stats);
    this.emit('dynamic-stats', stats);
  }

  onStaticStats(stats) {
    // console.log("static stats",stats);
    this.emit('static-stats', stats);
  }

  onFlencyReport(stats) {
	  this.emit('flency-report', stats);
  }
  stop() {
    clearInterval(this.generateTimer);
    this.isStart = false;
  }
}

function nativeStatsToMap(nativeResults) {
  let m = new Map();
  nativeResults.forEach(report => {
    let r = {};
    r.type = report.type;
    r.id = report.id;
    r.timestamp = report.timestamp;

    let names = report.names();

    names.forEach(name => {
      r[name] = report.stat(name);
    });

    m.set(report.id, r)
  });

  // console.log("map stats",m);
  return m
}

function splitStaticDynamicStats(statsMap) {
  // const staticList = [];
  const staticList = ["localcandidate", "remotecandidate", "googTrack",
    "googLibjingleSession", "googCertificate", "googComponent"
  ];
  let staticMap = new Map();
  let dynamicMap = new Map();
  for (let [key, value] of statsMap) {
    if (staticList.includes(value.type)) {
      staticMap.set(key, value)
    } else {
      if (value.type == "googCandidatePair") {
        if (value.googActiveConnection == 'true') {
          dynamicMap.set(key, formatValue(value));
        }
      } else {
        dynamicMap.set(key, formatValue(value));
      }
    }
  }
  return [staticMap, dynamicMap]
}

const formatValue = (object) => {
  let newO = {};
  for (let i in object) {
    if (object.hasOwnProperty(i)) {
      if (!isNaN(Number(object[i]))) {
        newO[i] = Number(object[i])
      } else {
        newO[i] = object[i];
      }
    }
  }
  if (newO.timestamp) {
    newO.timestamp = new Date(newO.timestamp).toISOString();
  }
  return newO;
};

const toDecimal = (x) => {
  let f = parseFloat(x);
  if (isNaN(f)) {
    return x;
  }
  return parseFloat(f.toFixed(2));
};


module.exports = WebRTCStatsParser;
