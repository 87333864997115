/**
 * 实时猫事件信息
 * @prop {external:Integer} code 事件代号
 * @prop {external:Object}  data 事件数据
 */
class RTCatEvent {

  constructor({
    code,
    data
  }) {
    this.code = code;
    this.data = data;
  }

}

const EventCode = {

  REPUBLISH_START: 100,
  REPUBLISH_ERROR: 101,
  REPUBLISH_END: 102,

  RESUBSCRIBE_START: 110,
  RESUBSCRIBE_ERROR: 111,
  RESUBSCRIBE_END: 112,

  RTC_CONNECTED: 130,
  RTC_FAILED: 131,
  RTC_LOW_FRAMERATE: 132,
  RTC_FREEZE: 133,
  RTC_MASSIVE_FREEZE: 134,

  ROOT_SERVER_DOWN: 140,
  ROUTER_SERVER_DOWN: 141,

  NEW_PUBLISHER: 150,
}

RTCatEvent.EventCode = EventCode


module.exports = RTCatEvent;