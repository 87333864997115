/**
 * Created by congchen on 12/12/16.
 */

const isMoz = !!navigator.mozGetUserMedia;

const LogPrefix = {
    2 : '%c [ VERBOSE ] ',
    3 : '%c [ DEBUG ] ',
    4 : '%c [ INFO ] ',
    5 : '%c [ WARN ] ',
    6 : '%c [ ERROR ] ',
    7 : '%c [ ASSERT ] '
};

const LogStyle = {
    2 : 'color: #9abff9',
    3 : 'color: #5c95f2',
    4 : 'color: #1a66e0;font-weight:bold;',
    5 : 'color: #aaaf0c;font-weight:bold;',
    6 : 'color: #ff4314;font-weight:bold;',
    7 : 'color: #a52b2b;font-weight:bold;'
};


let L = {
    log:(level,message)=>{
        if(level >= L.logLevel){
            let logMessage = LogPrefix[level] + message.join(' ');
            if( level == 2 ){
                console.log(logMessage,LogStyle[level]);
            }else if(level == 3 || level == 4 ){
                if (isMoz){
                    console.error(logMessage,LogStyle[level]);
                }else{
                    console.trace(logMessage,LogStyle[level]);
                }
            }
            else{
                console.error(logMessage,LogStyle[level]);
            }
        }
    },
    V :(...m)=>L.log(L.LogLevels.VERBOSE,m),
    D :(...m)=>L.log(L.LogLevels.DEBUG,m),
    I :(...m)=>L.log(L.LogLevels.INFO,m),
    W :(...m)=>L.log(L.LogLevels.WARN,m),
    E :(...m)=>L.log(L.LogLevels.ERROR,m),
    A :(...m)=>L.log(L.LogLevels.ASSERT,m),
    setLogLevel :l=>L.logLevel=l,
    LogLevels:{
        VERBOSE : 2,
        DEBUG   : 3,
        INFO    : 4,
        WARN    : 5,
        ERROR   : 6,
        ASSERT  : 7
    },
};




L.logLevel = L.LogLevels.VERBOSE;

module.exports = L;