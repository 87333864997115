const EventEmitter = require('events').EventEmitter

// https://www.w3.org/TR/webrtc/#enqueue-an-operation
// 4.4.1.2 Enqueue an operation

class Queue extends EventEmitter {

  constructor() {
    super();
    // Queue of pending commands. 
    // @type {Array<Function>}
    this._commands = [];
  }

  clear(error) {
    this._commands.forEach(command => {
      command.error = error || new Error('clear');
    });
  }

  /**
   * @param {Function} task - Function that returns a Pormise or a value directly.
   *
   * @async
   */
  async push(task) {
    if (typeof task !== 'function')
      throw new TypeError('given task is not a function');

    return new Promise((resolve, reject) => {

      const command = {
        task,
        resolve,
        reject
      }

      // Append command to the queue.
      this._commands.push(command);

      // And run it if the only command in the queue is the new one.
      if (this._commands.length === 1)
        this._next();
    });
  }

  async _next() {
    // Take the first command.
    const command = this._commands[0];

    if (command.error) {
      command.reject(command.error);
    } else {
      await this._awaitForResult(command);
    }

    // Remove the first command from the queue.
    this._commands.shift();

    if (this._commands.length === 0) {
      this.emit('empty', this.id);
    } else {
      // And continue.
      this._next();
    }
  }

  async _awaitForResult(command) {

    try {
      const result = await command.task();

      // Resolve the command with the given result (if any).
      command.resolve(result);

    } catch (error) {

      // Reject the command with the error.
      command.reject(error);

    }
  }
}

module.exports = Queue;