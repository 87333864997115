const RTCatLog = require('./log');

class SdpUtils {
  static mergeConstraints(cons1, cons2) {
    if (!cons1 || !cons2) {
      return cons1 || cons2;
    }
    var merged = cons1;
    for (var key in cons2) {
      if (cons2.hasOwnProperty(key)) {
        merged[key] = cons2[key];
      }
    }
    return merged;
  }

  static iceCandidateType(candidateStr) {
    return candidateStr.split(' ')[7];
  }

  static maybeSetOpusOptions(sdp, params) {
    if (params.opusStereo === true) {
      sdp = SdpUtils.setCodecParam(sdp, "opus/48000", "stereo", "1");
      sdp = SdpUtils.setCodecParam(sdp, "opus/48000", "sprop-stereo", "1");
    } else {
      if (params.opusStereo === false) {
        sdp = SdpUtils.removeCodecParam(sdp, "opus/48000", "stereo");
        sdp = SdpUtils.removeCodecParam(sdp, "opus/48000", "sprop-stereo");
      }
    }
    if (params.opusFec === "true") {
      sdp = SdpUtils.setCodecParam(sdp, "opus/48000", "useinbandfec", "1");
    } else {
      if (params.opusFec === "false") {
        sdp = SdpUtils.removeCodecParam(sdp, "opus/48000", "useinbandfec");
      }
    }
    if (params.opusDtx === "true") {
      sdp = SdpUtils.setCodecParam(sdp, "opus/48000", "usedtx", "1");
    } else {
      if (params.opusDtx === "false") {
        sdp = SdpUtils.removeCodecParam(sdp, "opus/48000", "usedtx");
      }
    }
    if (params.opusMaxPbr) {
      sdp = SdpUtils.setCodecParam(sdp, "opus/48000", "maxplaybackrate", params.opusMaxPbr);
      sdp = SdpUtils.setCodecParam(sdp, "opus/48000", "sprop-maxcapturerate", params.opusMaxPbr);
    }

    if (params.bandwidth) {
      sdp = SdpUtils.setCodecParam(sdp, "opus/48000", "maxaveragebitrate", params.bandwidth);
    }

    return sdp;
  }

  static maybeSetAudioSendBitRate(sdp, params) {
    if (!params.audioSendBitrate) {
      return sdp;
    }
    console.log('Prefer audio send bitrate: ' + params.audioSendBitrate);
    return preferBitRate(sdp, params.audioSendBitrate, 'audio');
  }


  static maybeSetAudioReceiveBitRate(sdp, params) {
    if (!params.audioRecvBitrate) {
      return sdp;
    }
    console.log('Prefer audio receive bitrate: ' + params.audioRecvBitrate);
    return preferBitRate(sdp, params.audioRecvBitrate, 'audio');
  }

  static maybeSetVideoSendBitRate(sdp, params) {
    if (!params.videoSendBitrate) {
      return sdp;
    }
    //console.log('Prefer video send bitrate: ' + params.videoSendBitrate);
    return SdpUtils.preferBitRate(sdp, params.videoSendBitrate, 'video');
  }

  static maybeSetVideoReceiveBitRate(sdp, params) {
    if (!params.videoRecvBitrate) {
      return sdp;
    }
    console.log('Prefer video receive bitrate: ' + params.videoRecvBitrate);
    return preferBitRate(sdp, params.videoRecvBitrate, 'video');
  }

  // Add a b=AS:bitrate line to the m=mediaType section.
  static preferBitRate(sdp, bitrate, mediaType) {
    var sdpLines = sdp.split('\r\n');

    // Find m line for the given mediaType.
    var mLineIndex = SdpUtils.findLine(sdpLines, 'm=', mediaType);
    if (mLineIndex === null) {
      console.log('Failed to add bandwidth line to sdp, as no m-line found');
      return sdp;
    }

    // Find next m-line if any.
    var nextMLineIndex = SdpUtils.findLineInRange(sdpLines, mLineIndex + 1, -1, 'm=');
    if (nextMLineIndex === null) {
      nextMLineIndex = sdpLines.length;
    }

    // Find c-line corresponding to the m-line.
    var cLineIndex = SdpUtils.findLineInRange(sdpLines, mLineIndex + 1,
      nextMLineIndex, 'c=');
    if (cLineIndex === null) {
      console.log('Failed to add bandwidth line to sdp, as no c-line found');
      return sdp;
    }

    // Check if bandwidth line already exists between c-line and next m-line.
    var bLineIndex = SdpUtils.findLineInRange(sdpLines, cLineIndex + 1,
      nextMLineIndex, 'b=AS');
    if (bLineIndex) {
      sdpLines.splice(bLineIndex, 1);
    }

    // Create the b (bandwidth) sdp line.
    var bwLine = 'b=AS:' + bitrate;
    // As per RFC 4566, the b line should follow after c-line.
    sdpLines.splice(cLineIndex + 1, 0, bwLine);
    sdp = sdpLines.join('\r\n');
    // console.log(sdp);
    return sdp;
  }

  static setVideoSendBitRate(sdp, start, min = 0, max = 0) {

    let sdpLines = sdp.split('\r\n');

    // Search for m line.
    let mLineIndex = SdpUtils.findLine(sdpLines, 'm=', 'video');
    if (mLineIndex === null) {
      RTCatLog.W('Failed to find video m-line and set send bitrate');
      return sdp;
    }

    //find video codec
    const codec_vp8 = 'VP8/90000';
    const codec_h264 = 'H264/90000';

    let codec;
    if (sdp.includes(codec_vp8)) {
      codec = codec_vp8
    } else if (sdp.includes(codec_h264)) {
      codec = codec_h264;
    }

    if (min != 0) {
      sdp = SdpUtils.setCodecParam(sdp, codec, 'x-google-min-bitrate', `${min}`);
    }
    sdp = SdpUtils.setCodecParam(sdp, codec, 'x-google-start-bitrate', `${start}`);

    if (max != 0) {
      sdp = SdpUtils.setCodecParam(sdp, codec, 'x-google-max-bitrate', `${max}`);
    }

    return sdp;
  }

  static removePayloadTypeFromMline(mLine, payloadType) {
    mLine = mLine.split(' ');
    for (var i = 0; i < mLine.length; ++i) {
      if (mLine[i] === payloadType.toString()) {
        mLine.splice(i, 1);
      }
    }
    return mLine.join(' ');
  }

  static removeCodecByName(sdpLines, codec) {
    var index = SdpUtils.findLine(sdpLines, 'a=rtpmap', codec);
    if (index === null) {
      return sdpLines;
    }
    var payloadType = SdpUtils.getCodecPayloadTypeFromLine(sdpLines[index]);
    sdpLines.splice(index, 1);

    // Search for the video m= line and remove the codec.
    var mLineIndex = SdpUtils.findLine(sdpLines, 'm=', 'video');
    if (mLineIndex === null) {
      return sdpLines;
    }
    sdpLines[mLineIndex] = SdpUtils.removePayloadTypeFromMline(sdpLines[mLineIndex],
      payloadType);
    return sdpLines;
  }

  static removeCodecByPayloadType(sdpLines, payloadType) {
    var index = SdpUtils.findLine(sdpLines, 'a=rtpmap', payloadType.toString());
    if (index === null) {
      return sdpLines;
    }
    sdpLines.splice(index, 1);

    // Search for the video m= line and remove the codec.
    var mLineIndex = SdpUtils.findLine(sdpLines, 'm=', 'video');
    if (mLineIndex === null) {
      return sdpLines;
    }
    sdpLines[mLineIndex] = removePayloadTypeFromMline(sdpLines[mLineIndex],
      payloadType);
    return sdpLines;
  }

  static maybeRemoveVideoFec(sdp, params) {
    if (params.videoFec !== 'false') {
      return sdp;
    }

    var sdpLines = sdp.split('\r\n');

    var index = SdpUtils.findLine(sdpLines, 'a=rtpmap', 'red');
    if (index === null) {
      return sdp;
    }
    var redPayloadType = getCodecPayloadTypeFromLine(sdpLines[index]);
    sdpLines = removeCodecByPayloadType(sdpLines, redPayloadType);

    sdpLines = removeCodecByName(sdpLines, 'ulpfec');

    // Remove fmtp lines associated with red codec.
    index = SdpUtils.findLine(sdpLines, 'a=fmtp', redPayloadType.toString());
    if (index === null) {
      return sdp;
    }
    var fmtpLine = parseFmtpLine(sdpLines[index]);
    var rtxPayloadType = fmtpLine.pt;
    if (rtxPayloadType === null) {
      return sdp;
    }
    sdpLines.splice(index, 1);

    sdpLines = removeCodecByPayloadType(sdpLines, rtxPayloadType);
    return sdpLines.join('\r\n');
  }

  // Promotes |audioSendCodec| to be the first in the m=audio line, if set.
  static maybePreferAudioSendCodec(sdp, params) {
    return SdpUtils.maybePreferCodec(sdp, 'audio', 'send', params.audioSendCodec);
  }

  // Promotes |audioRecvCodec| to be the first in the m=audio line, if set.
  static maybePreferAudioReceiveCodec(sdp, params) {
    return SdpUtils.maybePreferCodec(sdp, 'audio', 'receive', params.audioRecvCodec);
  }

  // Promotes |videoSendCodec| to be the first in the m=audio line, if set.
  static maybePreferVideoSendCodec(sdp, params) {
    return SdpUtils.maybePreferCodec(sdp, 'video', 'send', params.videoSendCodec);
  }

  // Promotes |videoRecvCodec| to be the first in the m=audio line, if set.
  static maybePreferVideoReceiveCodec(sdp, params) {
    return SdpUtils.maybePreferCodec(sdp, 'video', 'receive', params.videoRecvCodec);
  }

  // Sets |codec| as the default |type| codec if it's present.
  // The format of |codec| is 'NAME/RATE', e.g. 'opus/48000'.
  static maybePreferCodec(sdp, type, dir, codec) {
    var str = type + ' ' + dir + ' codec';
    if (!codec) {
      console.log('No preference on ' + str + '.');
      return sdp;
    }

    // console.log('Prefer ' + str + ': ' + codec);

    var sdpLines = sdp.split('\r\n');

    // Search for m line.
    var mLineIndex = SdpUtils.findLine(sdpLines, 'm=', type);

    if (mLineIndex === null) {
      return sdp;
    }

    // If the codec is available, set it as the default in m line.
    var payload = SdpUtils.getCodecPayloadType(sdpLines, codec);
    if (payload) {
      sdpLines[mLineIndex] = SdpUtils.setDefaultCodec(sdpLines[mLineIndex], payload);
    }

    sdp = sdpLines.join('\r\n');
    return sdp;
  }

  // Set fmtp param to specific codec in SDP. If param does not exists, add it.
  static setCodecParam(sdp, codec, param, value) {
    var sdpLines = sdp.split('\r\n');

    var fmtpLineIndex = SdpUtils.findFmtpLine(sdpLines, codec);

    var fmtpObj = {};
    if (fmtpLineIndex === null) {
      var index = SdpUtils.findLine(sdpLines, 'a=rtpmap', codec);
      if (index === null) {
        return sdp;
      }
      var payload = SdpUtils.getCodecPayloadTypeFromLine(sdpLines[index]);
      fmtpObj.pt = payload.toString();
      fmtpObj.params = {};
      fmtpObj.params[param] = value;
      sdpLines.splice(index + 1, 0, SdpUtils.writeFmtpLine(fmtpObj));
    } else {
      fmtpObj = SdpUtils.parseFmtpLine(sdpLines[fmtpLineIndex]);
      fmtpObj.params[param] = value;
      sdpLines[fmtpLineIndex] = SdpUtils.writeFmtpLine(fmtpObj);
    }

    sdp = sdpLines.join('\r\n');
    return sdp;
  }

  // Remove fmtp param if it exists.
  static removeCodecParam(sdp, codec, param) {
    var sdpLines = sdp.split('\r\n');

    var fmtpLineIndex = SdpUtils.findFmtpLine(sdpLines, codec);
    if (fmtpLineIndex === null) {
      return sdp;
    }

    var map = SdpUtils.parseFmtpLine(sdpLines[fmtpLineIndex]);
    delete map.params[param];

    var newLine = SdpUtils.writeFmtpLine(map);
    if (newLine === null) {
      sdpLines.splice(fmtpLineIndex, 1);
    } else {
      sdpLines[fmtpLineIndex] = newLine;
    }

    sdp = sdpLines.join('\r\n');
    return sdp;
  }

  // Split an fmtp line into an object including 'pt' and 'params'.
  static parseFmtpLine(fmtpLine) {
    var fmtpObj = {};
    var spacePos = fmtpLine.indexOf(' ');
    var keyValues = fmtpLine.substring(spacePos + 1).split(';');

    var pattern = new RegExp('a=fmtp:(\\d+)');
    var result = fmtpLine.match(pattern);
    if (result && result.length === 2) {
      fmtpObj.pt = result[1];
    } else {
      return null;
    }

    var params = {};
    for (var i = 0; i < keyValues.length; ++i) {
      var pair = keyValues[i].split('=');
      if (pair.length === 2) {
        params[pair[0]] = pair[1];
      }
    }
    fmtpObj.params = params;

    return fmtpObj;
  }

  // Generate an fmtp line from an object including 'pt' and 'params'.
  static writeFmtpLine(fmtpObj) {
    if (!fmtpObj.hasOwnProperty('pt') || !fmtpObj.hasOwnProperty('params')) {
      return null;
    }
    var pt = fmtpObj.pt;
    var params = fmtpObj.params;
    var keyValues = [];
    var i = 0;
    for (var key in params) {
      if (params.hasOwnProperty(key)) {
        keyValues[i] = key + '=' + params[key];
        ++i;
      }
    }
    if (i === 0) {
      return null;
    }

    return 'a=fmtp:' + pt.toString() + ' ' + keyValues.join(';');
  }

  // Find fmtp attribute for |codec| in |sdpLines|.
  static findFmtpLine(sdpLines, codec) {
    // Find payload of codec.
    var payload = SdpUtils.getCodecPayloadType(sdpLines, codec);
    // Find the payload in fmtp line.
    return payload ? SdpUtils.findLine(sdpLines, 'a=fmtp:' + payload.toString()) : null;
  }

  // Find the line in sdpLines that starts with |prefix|, and, if specified,
  // contains |substr| (case-insensitive search).
  static findLine(sdpLines, prefix, substr) {
    return SdpUtils.findLineInRange(sdpLines, 0, -1, prefix, substr);
  }

  // Find the line in sdpLines[startLine...endLine - 1] that starts with |prefix|
  // and, if specified, contains |substr| (case-insensitive search).
  static findLineInRange(sdpLines, startLine, endLine, prefix, substr) {
    // console.log("prefix : ",prefix ," substr :",substr)
    var realEndLine = endLine !== -1 ? endLine : sdpLines.length;
    for (var i = startLine; i < realEndLine; ++i) {
      if (sdpLines[i].indexOf(prefix) === 0) {
        if (!substr ||
          sdpLines[i].toLowerCase().indexOf(substr.toLowerCase()) !== -1) {
          return i;
        }
      }
    }
    return null;
  }

  // Gets the codec payload type from sdp lines.
  static getCodecPayloadType(sdpLines, codec) {
    var index = SdpUtils.findLine(sdpLines, 'a=rtpmap', codec);
    return index ? SdpUtils.getCodecPayloadTypeFromLine(sdpLines[index]) : null;
  }

  // Gets the codec payload type from an a=rtpmap:X line.
  static getCodecPayloadTypeFromLine(sdpLine) {
    var pattern = new RegExp('a=rtpmap:(\\d+) [a-zA-Z0-9-]+\\/\\d+');
    var result = sdpLine.match(pattern);
    return (result && result.length === 2) ? result[1] : null;
  }

  // Returns a new m= line with the specified codec as the first one.
  static setDefaultCodec(mLine, payload) {
    var elements = mLine.split(' ');

    // Just copy the first three parameters; codec order starts on fourth.
    var newLine = elements.slice(0, 3);

    // Put target payload first and copy in the rest.
    newLine.push(payload);
    for (var i = 3; i < elements.length; i++) {
      if (elements[i] !== payload) {
        newLine.push(elements[i]);
      }
    }

    return newLine.join(' ');
  }


  static updateBandwidthRestriction(sdp, bandwidth) {
    return SdpUtils.maybeSetVideoSendBitRate(sdp, {
      videoSendBitrate: bandwidth
    });
  }

  //self
  static updateVideoBandwidth(sdp, bandwidth) {
    return SdpUtils.preferBitRate(sdp, bandwidth, 'video');
  }

  static updateAudioBandwidth(sdp, bandwidth) {
    return SdpUtils.preferBitRate(sdp, bandwidth, 'audio');
  }

  static filterCandidate(sdp, filterPass) {
    let sdpLines = sdp.sdp.split('\r\n');
    let rvLines = [];
    for (let i in sdpLines) {
      if (sdpLines.hasOwnProperty(i)) {
        if (sdpLines[i].includes("a=candidate:")) {
          let ice = sdpLines[i].slice(2);
          if (filterPass(ice)) {
            rvLines.push(sdpLines[i]);
          } else {
            //
          }
        } else {
          rvLines.push(sdpLines[i]);
        }
      }
    }

    sdp.sdp = rvLines.join('\r\n');
    return sdp;
  }

  static opusNACK(sdp) {
    var sdpLines = sdp.split('\r\n');

    let mLineIndex = SdpUtils.findLine(sdpLines, 'm=', 'audio');
    if (mLineIndex === null) {
      return sdp;
    }
    // If the codec is available, set it as the default in m line.
    var index = SdpUtils.findLine(sdpLines, 'a=rtpmap', 'opus/48000');
    sdpLines.splice(index + 1, 0, `a=rtcp-fb:${SdpUtils.getCodecPayloadTypeFromLine(sdpLines[index])} nack`);
    return sdpLines.join('\r\n');
  }

  static maybePreferH264VideoCodec(sdp, profileLevelId, asymmetry = 1, packetization = 1) {

    var sdpLines = sdp.split('\r\n');

    var index = SdpUtils.findLine(sdpLines, 'a=fmtp', `level-asymmetry-allowed=${asymmetry};packetization-mode=${packetization};profile-level-id=${profileLevelId}`);

    if (index === null) {
      return sdp;
    }

    var fmtpLine = SdpUtils.parseFmtpLine(sdpLines[index]);
    var payload = fmtpLine.pt;


    var mLineIndex = SdpUtils.findLine(sdpLines, 'm=', 'video');

    if (mLineIndex === null) {
      return sdp;
    }

    if (payload) {
      sdpLines[mLineIndex] = SdpUtils.setDefaultCodec(sdpLines[mLineIndex], payload);
    }

    RTCatLog.I(`maybePreferH264VideoCodec ok`);
    return sdpLines.join('\r\n');
  }

}

// console.log(sdp)
module.exports = SdpUtils;
