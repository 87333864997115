const RTCatError = require('../../../error');

function error(params) {

  const error = params.error;

  if (error) {

    const code = error.code;
    const name = error.message
    const message = error.detail;

    const newError = new RTCatError({
      code,
      name,
      message
    });

    this.emit('rtcat-error', newError);
  }

}


module.exports = error;
