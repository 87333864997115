const RTCatLog = require('../../utils/log');
const EventEmitter = require('events').EventEmitter;
const JsonRPC = require('@present/json-rpc').JsonRPC;

const LogServer = require('../log/index');

const RTCatError = require('../../error');
const SessionUser = require('../../model/session_user');

const onErrorNotify = require('./notify/error');
const onPublisherNotify = require('./notify/publisher');
const onUnpublishNotify = require('./notify/unpublish');
const onKickoutNotify = require('./notify/kickout');
const onMediaChangedNotify = require('./notify/media_changed');

class SignalServer extends EventEmitter {

  /**
   * @constructor
   * @param {*} url         signal server url
   * @param {*} token       authentication certificate
   * @param {*} options 
   */
  constructor(url, token, options = {
    enableLog: true,
    logServer: 'wss://log.webrtc.baijiayun.com:12000/ws',
    encrypt: true,
    version: '1.0.0'
  }) {
    super();

    this.uuid = Math.round(Math.random() * 100000000); // 1/100 000 000 collision probability

    let encrypt = true;
    if (options.encrypt !== undefined) {
      encrypt = options.encrypt;
    }

    this._token = token;

    if (encrypt) {
      this._url = `${url}?tokenId=${token}&version=${options.version}`;
    } else {
      this._url = `${url}?tokenId=${token}&noEncrypt=true&version=${options.version}`
    }

    if (options.enableLog) {
      this._logUrl = options.logServer || 'wss://log.webrtc.baijiayun.com:12000/ws';
    }

    RTCatLog.I(this._url);

    this._options = options;

    this._sfuSessions = new Map();

    this.internalEvents = new EventEmitter();

    // bind notification handlers

    this.onErrorNotify = onErrorNotify.bind(this);
    this.onKickoutNotify = onKickoutNotify.bind(this);
    this.onPublisherNotify = onPublisherNotify.bind(this);
    this.onUnpublishNotify = onUnpublishNotify.bind(this);
    this.onMediaChangedNotify = onMediaChangedNotify.bind(this);

    this.state = SignalServer.DISCONNECTED;
  }

  connect() {

    if (this.state === SignalServer.CONNECTING || this.state === SignalServer.CONNECTED) {
      throw new RTCatError({
        code: RTCatError.ErrorCode.LOCAL_GENERA,
        message: `${this.uuid} Already CONNECTING | CONNECTED`
      });
    }

    if (this._logUrl) {
      this._logServer = new LogServer(this._token, this._logUrl);

      this._logServer.onconnnected = () => {

        RTCatLog.I(`${this.uuid} log server connnected`);

      };

      this._logServer.ondisconnected = () => {

        RTCatLog.W(`${this.uuid} log server disconnected`);

        if (this.state === SignalServer.CONNECTED || this.state === SignalServer.CONNECTING)
          setTimeout(() => {
            // try to reconnencted to log server
            this._logServer.connect();
          }, 5000);
      };

      this._logServer.onerror = (e) => {
        RTCatLog.E(e);
      };

      this._logServer.connect();
    }

    this._jsonRPC = new JsonRPC({
      url: this._url,
      WebSocket: WebSocket
    });

    this._jsonRPC.connect();

    this.state = SignalServer.CONNECTING;

    this._jsonRPC.on('error', (event) => {

      RTCatLog.E(`${this.uuid} signal server rpc error`);

    });

    const onclose = (e) => {
      this._emitClose(e);
    }

    this._jsonRPC.on('close', onclose);

    this._jsonRPC.on('open', () => {

      RTCatLog.I(`${this.uuid} on signal server open`);

      this._jsonRPC.removeListener('close', onclose);

      this._jsonRPC.onNotify('error', this.onErrorNotify);
      this._jsonRPC.onNotify('publisher', this.onPublisherNotify);
      this._jsonRPC.onNotify('unpublish', this.onUnpublishNotify);
      this._jsonRPC.onNotify('kickout', this.onKickoutNotify);
      this._jsonRPC.onNotify('mediaChanged', this.onMediaChangedNotify);

      this._jsonRPC.on('close', (e) => {
        this._emitClose(e);
      });

      this.state = SignalServer.CONNECTED;

      this.emit('open');
    });
  }

  close() {
    if (this.state !== SignalServer.DISCONNECTING || this.state !== SignalServer.DISCONNECTED) {
      this._jsonRPC.close();
      this.state = SignalServer.DISCONNECTING;
    }
  }

  /**
   * create sfu session
   * @function
   * @param {external:Object} options
   * @param {external:String} [options.area] join area.
   * @param {external:Object} [options.attr] 自定义属性
   * @returns {SessionUser} SessionUser
   */
  async joinSession(userInfo = {
    sessionId,
    userId,
    userNumber
  }, options = {}) {

    const sessionId = userInfo.sessionId;
    const userId = userInfo.userId;
    const userNumber = userInfo.userNumber;

    if (this.state !== SignalServer.CONNECTED) {
      throw new RTCatError({
        code: RTCatError.ErrorCode.LOCAL_GENERA,
        message: `${this.uuid} not-connect`
      });
    }

    let session = this._sfuSessions.get(sessionId);

    let sessionUser = null;

    if (!session) {
      session = new Map();
      this._sfuSessions.set(sessionId, session);
    } else {
      sessionUser = session.get(userId);
    }

    if (sessionUser) {
      throw new RTCatError({
        code: RTCatError.ErrorCode.ALREADY_EXIST,
        message: `${this.uuid}, ${sessionId}@${userId} ALREADY-EXIST`
      });
    }

    sessionUser = new SessionUser({
      signalServer: this,
      logServer: this._logServer,
      sessionId,
      userId,
      userNumber
    });

    sessionUser.on('quit', () => {
      session.delete(userId);
      if (session.size === 0) {
        this._sfuSessions.delete(sessionId);
      }
    });

    session.set(userId, sessionUser);

    try {
      await sessionUser.join(options);
    } catch (e) {
      session.delete(userId);
      if (session.size === 0) {
        this._sfuSessions.delete(sessionId);
      }

      throw e;
    }

    return sessionUser;
  }

  getSessionUser(sid, uid) {
    const session = this._sfuSessions.get(sid);
    if (session) {
      return session.get(uid);
    }
  }

  async getAreas() {
    try {

      const areas = await this.request('getAreas');

      return areas;

    } catch (error) {

      const code = error.code;
      const name = error.message;
      const message = error.data ? error.data.detail : 'getAreas request failed';

      throw new RTCatError({
        code,
        name,
        message
      });
    }
  }

  async getMediaServers() {

    try {

      const servers = await this.request('getServers');

      return servers;

    } catch (error) {

      const code = error.code;
      const name = error.message;
      const message = error.data ? error.data.detail : 'getMediaServers request failed';

      throw new RTCatError({
        code,
        name,
        message
      });
    }
  }

  /**
   * 
   * @param {*} method 
   * @param {*} param 
   * @throws
   */
  async request(method, param, timeout = 8000) {
    return this._jsonRPC.request(method, param, timeout);
  }

  _emitClose(e) {

    RTCatLog.I(`${this.uuid} emit signal server close`);

    this.state = SignalServer.DISCONNECTED;

    this._sfuSessions.clear();

    this.internalEvents.emit('close', e);
    this.internalEvents.removeAllListeners();

    this.emit('close', e);

    this.removeAllListeners();

    if (this._logServer) {
      this._logServer.close();
    }
  }
}

SignalServer.CONNECTING = 0;
SignalServer.CONNECTED = 1;
SignalServer.DISCONNECTING = 2
SignalServer.DISCONNECTED = 3;

module.exports = SignalServer;