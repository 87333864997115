const RTCatLog = require('../utils/log');
const Peer = require('./peer');
const EventEmitter = require('events').EventEmitter;
const WebRTCStatsParser = require('../utils/webrtc_stats_parser');
const Detect = require('../platform/detect');
const SdpUtils = require('../utils/sdp_utils');

class Connection extends EventEmitter {

  constructor(
    userId,
    stun_servers,
    role,
    options) {
    super();
    this.userId = userId;
    this.role = role;
    this.stun_servers = stun_servers;
    this.webrtcStatsParser = new WebRTCStatsParser(this.role,options);
    this.timeout = 10;
    this.closed = false;
  }

  setupRtcPeer(cpuDetect) {

    this.destroyRtcPeer();

    this._rtc_connected = false;

    this.iceRestartCount = 0;

    let peerConfig = {
      bundlePolicy: "max-bundle",
      sdpSemantics: 'unified-plan'
    };

    if (this.stun_servers.length == 0) {
      this.stun_servers = [
        "stun:60.205.205.124:443"
      ]
    }

    peerConfig.iceServers = [{
      urls: this.stun_servers,
    }];

    let isRecv = (this.role === 'subscriber');
    let offerConstraint = {
      offerToReceiveAudio: isRecv ? 1 : 0,
      offerToReceiveVideo: isRecv ? 1 : 0,
    };

    this.peer = new Peer({
      peerConfig,
      peerConstraints: {
        optional: [{
          "googCpuOveruseDetection": cpuDetect, // DegradationPreference ?

        },{ "googDscp": true } ]
      },
      offerConstraint,
      isSendNegoIce: true,
      isRecvNegoIce: true
    });

    this.peer.onice = ice => {
      if (ice.candidate.includes("tcp")) return;
      this.emit('rtc-ice', ice);
    };

    this.peer.onfailed = () => {
      // It is wired that sometimes onfailed will not fired.
      RTCatLog.I(`${this.peer.tag}: rtc-failed`)
      this._rtc_connected = false;
      this.emit('rtc-failed');
      this.setTimer(this.timeout);
    };

    this.peer.onconnected = () => {
      this.iceRestartCount = 0;
      this.webrtcStatsParser.start();
      RTCatLog.I(`${this.peer.tag}: rtc-connected`);
      this._rtc_connected = true;
      this.emit('rtc-connected');
      // update timer.
      this.setTimer(this.timeout);
    };

    this.attachWebRTCStatsParser();
  }

  destroyRtcPeer() {
    this.webrtcStatsParser.removeAllListeners();
    this.webrtcStatsParser.stop();

    if (this.peer) {
      this.peer.close();
      this.peer = null;
    }
  }

  attachWebRTCStatsParser() {
    this.webrtcStatsParser.on('get-stats', () => {
        this.peer.getStats(rs => {
          //console.log(`${this.unmutevideo},${this.unmuteaudio} mute?`);
          this.webrtcStatsParser.addStats(rs.result(),this.sendvideo && this.unmutevideo,this.sendaudio&& this.unmuteaudio);
        });
    });

    this.webrtcStatsParser.on('parsed-stats', stats => {
      this.emit('parsed-stats', stats);
    });

    this.webrtcStatsParser.on('dynamic-stats', stats => {
      this.emit('dynamic-stats', stats);
    });

    this.webrtcStatsParser.on('flency-report', stats => {
      stats.userId = this.userId;
      this.emit('flency-report', stats);
    });

  }

  async rtcMesHandler(mes) {

    this.check();

    if (mes.type === 'sdp') {
      if (this.role === 'subscriber') {

        const sdpStr = mes.sdp.sdp;

        let sdpLines = sdpStr.split('\r\n');

        let mindex = SdpUtils.findLine(sdpLines, 'm=', 'video');
        if (mindex) {
          if (mindex + 1 < sdpLines.length) {
            let index = SdpUtils.findLineInRange(sdpLines, mindex + 1, -1, 'a=', 'sendrecv');
            if (index) {
              sdpLines.splice(index, 1, 'a=inactive');
              mes.sdp.sdp = sdpLines.join('\r\n');
            }
          }
        }
      }

      await this.peer.foffer(mes.sdp);

    } else if (mes.type === 'ice') {

      await this.peer.addCandidate(mes.ice);
    }
  }

  setTimer(timeout = 10) {

    this.timeout = timeout;

    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {

      this.timer = null;

      if (!this.connected) {
        RTCatLog.W(`${this.userId} - ${this.role} rtc timeout, after ${timeout}s`);
        this.emit('rtc-failed');
      }

      this.setTimer(timeout); // check alive every timeout s

    }, timeout * 1000);

  }

  get connected() {
    if (!this.peer.pc) {
      return false;
    }

    if (this.peer.pc.connectionState &&
      this.peer.pc.connectionState !== 'connected') {
      return false;
    }

    // It's wired that sometimes failed event will not be fired.
    if (this.peer.pc.iceConnectionState === 'failed') {
      return false;
    }

    return this._rtc_connected;
  }

  async restartIce() {
    this.iceRestartCount++;
    return this.peer.restartIce();
  }

  check() {
    if (this.closed) {
      throw new Error('already closed');
    }
  }

  close() {
    if (this.closed) {
      return;
    }

    this.closed = true;

    this.removeAllListeners();

    this.destroyRtcPeer();

    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }
}


module.exports = Connection;
