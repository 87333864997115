const RTCatLog = require('../utils/log')
const Utils = require('../utils/utils');

// @todo add some feature: capture , mirror , fullscreen
// @todo(zsf, zzh, zgx): front-end adjustment.

/**
 * wrap <video> , and support plugin
 * video : https://developer.mozilla.org/en-US/docs/Web/HTML/Element/video
 */
class Player {

  constructor(container = 'body', local = true, size) {

    this.id = Utils.uuid();
    this.tag = `player-${this.id}`;

    if (container instanceof HTMLElement) {

      this._element = container;
      this._element.muted = local && (this._element.volume = 0);

    } else {

      this._element = document.createElement('video');
      this._element.muted = local && (this._element.volume = 0);

      this._element.__SELF__ = true;

      if (size) {
        this.setSize(size);
      }

      let fEle;
      if (!(fEle = document.querySelector(container))) {
        fEle = document.body;
        RTCatLog.W(this.tag, "constructor", "unknown container element, use body")
      }

      fEle.appendChild(this._element);
    }
  }

  play(stream) {

    if (this._element && stream) {
      this._element.setAttribute('autoplay', 'autoplay');
      this._element.pause();
      // fixme srcObject
      // this._element.src = URL.createObjectURL(stream);
      this._element.srcObject = stream;
      this._element.play();
    } else {
      //todo warning
    }

  }

  stop() {
    if (this._element && this._element.__SELF__) {
      this._element.remove();
      delete this._element;
    }
  }

  //todo change to size
  poster(poster) {
    this._element && this._element.setAttribute('poster', poster);
  }

  setSize({
    width: width,
    height: height
  }) {
    this._element && (this._element.setAttribute('width', width), this._element.setAttribute('height', height));
  }

  audioOutput(device) {
    this._element.setSinkId(device);
  }

  mute(b) {
    this._element.muted = !!b;
  }

  load(plugin) {
    plugin.source = this._element;
  }

  get element() {
    return this._element;
  }
}

module.exports = Player;
