function mediaChanged(params) {

  const sid = params._sid;
  const cid = params._cid;

  const sessionUser = this.getSessionUser(sid, cid);
  if (sessionUser) {
    const args = params.args;
    sessionUser.emit('#mediaChanged', args);
  }
}


module.exports = mediaChanged;
