class VideoQuality {
  constructor(role) {
    this.TAG = "MOS";
    this.videoinfo_list = [];
    this.fps_window = 5;
    this.totalPauseTimes = 0;
    this.frameJitterTimes = 0;
    this.count = 0;
    this.avg = 0.1;
    this.role = role;
    this.standardFps = null;
  }

  addVideoInfo(jitter, packetsLossRate, width, height, fps, rtt) {
    const videoinfo = new VideoInfo(jitter, packetsLossRate, width, height, fps, rtt);
    this.videoinfo_list.push(videoinfo);
  }

  getSubjectQuality() {
    let one = 0;
    let two = 0;
    let mos;
    let data;
    if (this.standardFps) {
      data = this.getFpsStdDev() / this.standardFps;
      // console.log('video quality',this.role + ' standard '+this.standardFps);
      if (this.avg < this.standardFps / 5.0 * 4) {
        one = 1;
      }

      if (this.avg < this.standardFps / 2) {
        two = 1;
      }


    } else {
      data = this.getFpsStdDev() / this.avg;
      if (this.avg < 9)
        one = 1;
      if (this.avg < 4)
        two = 1;

    }

    if (data < 0.1)
      mos = 5 - 2 * one - 2 * two;
    else if (data < 0.3)
      mos = 4 - one - 2 * two;
    else if (data < 0.8)
      mos = 3 - two;
    else if (data < 1.1)
      mos = 2 - two;
    else
      mos = 1;


    // document.getElementById("output_mos").textContent = " Data: " + data.toFixed(2) + " one: " + one
    //     + " two: " + two + " AVG: "+ this.avg.toFixed(1) + " MOS: " + mos;
    //
    // console.log(one + "|" + two + "|" + data.toFixed(2) );

    // console.log(this.TAG ,this.role + " Data: " + data.toFixed(2) + " one: " + one
    //         + " two: " + two + " AVG: "+ this.avg + " MOS: " + mos);

    // if (mos==3 && (one + two >= 1)){
    //     console.log("FXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXK");
    //     console.log(this.TAG ,this.role + " Data: " + data.toFixed(2) + " one: " + one
    //         + " two: " + two + " AVG: "+ this.avg + " MOS: " + mos);
    // }

    return mos;
  }

  getFpsStdDev() {
    let sum = 0;
    let sum_v = 0;
    let index;
    let invalid_count = 0;
    let variance;
    let i;
    let standard_dev;

    //console.log(this.videoinfo_list);

    this.count = this.videoinfo_list.length;
    index = this.count - 1;


    if (index < invalid_count)
      return 10000;
    else {
      if (this.count < this.fps_window + invalid_count) {
        for (i = invalid_count; i <= index; ++i) {
          sum += this.videoinfo_list[i].fps;
        }
        // console.log(sum);

        this.avg = sum / (this.count - invalid_count);

        for (i = invalid_count; i <= index; ++i) {
          sum_v += Math.pow((this.videoinfo_list[i].fps - this.avg), 2);
        }

        variance = sum_v / (this.count - invalid_count);

      } else {
        for (i = index - (this.fps_window - 1); i <= index; ++i) {
          sum += this.videoinfo_list[i].fps;
        }

        this.avg = sum / this.fps_window;

        for (i = index - (this.fps_window - 1); i <= index; ++i) {
          sum_v = sum_v + Math.pow((this.videoinfo_list[i].fps - this.avg), 2);
        }

        variance = sum_v / this.fps_window;
      }


    }

    standard_dev = Math.sqrt(variance);


    // console.log(this.TAG,this.role +  " fps avg: "+this.avg + " standard dev: " + standard_dev);

    return standard_dev;
  }

  getRttQuality() {

    let sumRtt;
    // sumRtt = this.videoinfo_list.reduce(function (sum,value) {
    // 	// console.log(sum,value);
    // 	return sum + value.rtt;
    // },0);

    // if(this.count )
    let avgRtt = sumRtt / this.videoinfo_list.length;


    let emodel = 0;
    if (avgRtt / 2 >= 500)
      emodel = 1;
    else if (avgRtt / 2 >= 400)
      emodel = 2;
    else if (avgRtt / 2 >= 300)
      emodel = 3;
    else if (avgRtt / 2 >= 200)
      emodel = 4;
    else if (avgRtt / 2 < 200)
      emodel = 5;


    // console.log('rtt mos',this.role,'mos: ' + emodel + ' avgrtt: ' + avgRtt + ' length' + this.videoinfo_list.length);
    return emodel;
  }

  setStandardFps(fps) {
    this.standardFps = fps;
  }
}

class VideoInfo {
  constructor(jitter, packetsLossRate, width, height, fps, rtt) {
    this.jitter = parseInt(jitter);
    this.packetsLossRate = parseFloat(packetsLossRate);
    this.width = parseInt(width);
    this.height = parseInt(height);
    this.fps = parseInt(fps);
    this.rtt = parseInt(rtt);
  }
}

module.exports = VideoQuality;
