/**
 *  Javascript Error
 * Error : https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error
 */
const Errors = {
  screensharing_host_invaild: new Error('use domain for screensharing , not ip address.'),
  screensharing_extension_not_install: new Error('the screensharing extension is not installed or enabled.'),
  capturestream_not_supported_yet: new Error('the browser not support this feature now'),
  capturestream_should_enable_flag: new Error('chrome should enable flag(chrome://flags/#enable-experimental-web-platform-features) for this feature'),
  capturestream_permission_error: new Error('stream permission denied')
};

module.exports = Errors;
