const AbstractStream = require('./abstractstream');


/**
 * 远程流
 * @extends AbstractStream
 */
class RemoteStream extends AbstractStream {

  constructor(mediaStream, feedId) {
    super({
      mediaStream,
      local: false
    });
    this._feedId = feedId;
  }

  get feedId() {
    return this._feedId;
  }
  play(containerId, options) {

    const vTrack = this.videoTrack;
    this.videoTrack = null;

    super.play(containerId, options);

    setImmediate(() => {
      this.videoTrack = vTrack;
    });
  }

  /**
   * 设置音频输入设备
   * @param deviceId
   */
  changeOutputDevice(deviceId) {
    this.player.audioOutput(deviceId);
  }

  /**
   * 静音
   */
  mute() {
    this.player.mute(true);
  }

  /**
   * 取消静音
   */
  unmute() {
    this.player.mute(false);
  }

}

module.exports = RemoteStream;
