
const SdpUtils = require('../utils/sdp_utils');

async function codecTest(preferredCodec) {

  if(['H264','VP8','VP9'].includes(preferredCodec)){
    return new Promise(async (y, n) => {
      try {
        let stream = await navigator.mediaDevices.getUserMedia({video: true});
        let sender_peer = new RTCPeerConnection();
        let receiver_peer = new RTCPeerConnection();

        let supported = {
          encoder: {},
          decoder: {},
        };

        sender_peer.onicecandidate = event => {
          let candidate = event.candidate;
          if (candidate) {
            receiver_peer.addIceCandidate(candidate);
          }
        };

        sender_peer.oniceconnectionstatechange = async event => {
          if (sender_peer.iceConnectionState == 'completed') {
            setTimeout(async _ => {

              let s_promise = new Promise(y => {
                sender_peer.getStats(results => {
                  let encoder = {};
                  results.result().forEach(res => {
                    if (res.type == "ssrc") {
                      res.names().forEach(k => {
                        if (k == 'codecImplementationName') {
                          encoder.implementation = res.stat(k);
                        }
                        if (k == 'googCodecName') {
                          encoder.name = res.stat(k);
                        }
                      });
                    }
                  });
                  y(encoder);
                });
              });

              let r_promise = new Promise(y => {
                receiver_peer.getStats(results => {
                  let decoder = {};
                  results.result().forEach(res => {
                    if (res.type == "ssrc") {
                      res.names().forEach(k => {
                        if (k == 'codecImplementationName') {
                          decoder.implementation = res.stat(k);
                        }
                        if (k == 'googCodecName') {
                          decoder.name = res.stat(k);
                        }
                      });
                    }
                  });
                  y(decoder);
                });
              });

              let value = await Promise.all([s_promise, r_promise]);
              y({
                encoder:value[0],
                decoder:value[1],
                supported:supported
              });

              sender_peer.close();
              receiver_peer.close();
              stream.getVideoTracks()[0].stop();

            }, 1000);
          }
        };

        receiver_peer.onicecandidate = event => {
          let candidate = event.candidate;
          if (candidate) {
            sender_peer.addIceCandidate(candidate);
          }
        };

        sender_peer.addStream(stream);

        let sdp = await sender_peer.createOffer();

        let sdp_string = SdpUtils.maybePreferH264VideoCodec(sdp.sdp,'42e01f');

        sdp.sdp = sdp_string;

        supported.encoder = {
          'VP8': sdp.sdp.includes("VP8"),
          'H264': sdp.sdp.includes("H264"),
          'VP9': sdp.sdp.includes("VP9"),
        };

        await sender_peer.setLocalDescription(sdp);
        receiver_peer.setRemoteDescription(sdp);
        let answer = await receiver_peer.createAnswer();

        supported.decoder = {
          'VP8': answer.sdp.includes("VP8"),
          'H264': answer.sdp.includes("H264"),
          'VP9': answer.sdp.includes("VP9"),
        };

        await receiver_peer.setLocalDescription(answer);
        await sender_peer.setRemoteDescription(answer);
      } catch (e) {
        n(e);
      }
    });
  }else{
    return null;
  }


}


module.exports = codecTest;