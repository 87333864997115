const RTCatLog = require('../utils/log')

/**
 * wrap DataChannel
 * RTCDataChannel : https://developer.mozilla.org/en-US/docs/Web/API/RTCDataChannel
 */
class Channel {
  constructor(dc, pid, type = Channel.Type.Binary) {
    this.pid = pid;

    this.tag = `channel : label -> ${dc.label} , pid -> ${this.pid}`;

    this.dc = dc;
    this.dc.binaryType = type;

    this.onopen = e => RTCatLog.I(this.tag, "<onopen>");
    this.onmessage = e => RTCatLog.D(this.tag, "<onmessage>", e);
    this.onerror = e => RTCatLog.E(this.tag, "<onerror>", e);
    this.onclose = e => RTCatLog.I(this.tag, "<onclose>");

    this.dc.onopen = e => this.onopen();
    this.dc.onclose = e => this.onclose();
    this.dc.onerror = e => this.onerror(e);
    this.dc.onmessage = e => this.onmessage(e.data);

  }

  sendJSON(json) {
    let message = JSON.stringify(json);
    this.send(message);
  }

  send(str) {
    if (this.dc && (this.dc.readyState == "open")) {
      RTCatLog.D(this.tag, '<send>', str);
      this.dc.send(str);
    } else {
      RTCatLog.W(this.tag, '<send>', 'can not send message when channel is not open.');
    }
  }
}

Channel.Type = {
  Binary: 'arraybuffer',
  Blob: 'blob'
};

module.exports = Channel;
