const RTCatLog = require('../utils/log');
const Utils = require('../utils/utils');
const Channel = require('./channel');

function ToRtcMirrorMode(mode) {
  var mirror_mode = "no-mirror";
  switch(mode){
    case Peer.MirrorMode.NoMirror:
      mirror_mode = "no-mirror";
      break;
    case Peer.MirrorMode.HorizonMirror:
      mirror_mode = "horizon-mirror";
      break;
    case Peer.MirrorMode.VerticalMirror:
      mirror_mode = "vertical-mirror";
      break;
    case Peer.MirrorMode.HorizonVerticalMirror:
      mirror_mode = "horizon-vertical-mirror";
      break;
  }
  return mirror_mode;
}

/**
 * wrap PeerConnection
 * RTCPeerConnection : https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection
 * peer support bi-direction single stream , multi datachannel
 *
 * use isSendNegoIce when offer
 *
 * //todo(cc): fix isRecvNegoIce , when answer
 */
class Peer {
  constructor({
    peerConfig = {},
    id = Utils.uuid(),
    offerConstraint = {
      offerToReceiveAudio: 1,
      offerToReceiveVideo: 1
    },
    isSendNegoIce = false,
    isRecvNegoIce = false,
    peerConstraints = null
  }) {
    this.setId(id);

    this.isSendNegoIce = isSendNegoIce;
    this.isRecvNegoIce = isRecvNegoIce;

    this.sendNegoIce = false;
    this.recvNegoIce = false;

    this.iceQueue = [];
    this.recvIceQueue = [];

    this.ontrack = _ => {
      RTCatLog.I(this.tag, '<ontrack>')
    };
    this.onice = i => {
      RTCatLog.W(this.tag, '<onice>', `drop candidate : ${i}`)
    };
    this.onclose = _ => {
      RTCatLog.I(this.tag, '<onclose>')
    };
    this.ondisconnected = _ => {
      RTCatLog.I(this.tag, '<ondisconnected>');
    }
    this.onfailed = _ => {
      RTCatLog.I(this.tag, '<onfailed>');
    }
    this.onconnected = _ => {
      RTCatLog.I(this.tag, '<onconnected>')
    };
    this.onicestate = s => {
      RTCatLog.D(this.tag, '<onicestate>', s)
    };
    this.onicecomplete = _ => {
      RTCatLog.D(this.tag, '<onicecomplete>')
    };

    this.localstream = null;
    this.localMediaStream = null;

    //todo(cc): add updateice
    this.peerConfig = peerConfig;
    this.offerConstraint = offerConstraint;

    this.pc = new RTCPeerConnection(peerConfig, peerConstraints);

    this.pc.onicecandidate = event => {
      if (event.candidate == null || (event.candidate.candidate.indexOf('endOfCandidates') > 0)) {
        this.onicecomplete()
      } else {
        if (this.onice) {
          if (this.isSendNegoIce) {
            if (this.sendNegoIce) {
              this.onice(event.candidate)
            } else {
              this.iceQueue.push(event.candidate)
            }
          } else {
            this.onice(event.candidate)
          }
        }
      }
    };

    this.pc.ontrack = event => {
      if (event.track) {
        if (this.ontrack) {
          this.ontrack(event.track);
        }
      }
    };

    this.pc.oniceconnectionstatechange = e => {

      RTCatLog.D(this.tag, 'oniceconnectionstatechange', this.pc.iceConnectionState);

      this.onicestate(this.pc.iceConnectionState);

      switch (this.pc.iceConnectionState) {
        case 'closed':
          this.onclose();
          break;
        case 'disconnected': {
          this.ondisconnected();
          break;
        }
        case 'failed': {
          this.onfailed();
          break;
        }
        case 'connected':
          this.onconnected();
          break;
      }

    };

    this.pc.onnegotiationneeded = e => {
      RTCatLog.D(this.tag, 'onnegotiationneeded')
    }

    this.pc.onsignalingstatechange = e => {
      RTCatLog.D(this.tag, 'onsignalingstatechange', this.pc.signalingState)
    };

    this.sChannel = {};
    this.rChannel = {};
  }

  setId(id) {
    if (this.id) {
      RTCatLog.I(this.tag, '<setId>', `change id : ${this.id} -> ${id}`);
    }
    this.id = id;
    this.tag = `Peer : ${this.id}`
  }

  addCandidate(candidate) {
    if (this.isRecvNegoIce) {
      if (this.recvNegoIce) {
        this.pc.addIceCandidate(candidate);
      } else {
        this.recvIceQueue.push(candidate);
      }
    } else {
      this.pc.addIceCandidate(candidate);
    }
  }

  setMirror(mode){
    this.pc.getSenders().forEach(async sender => {

      if (sender.track && sender.track.kind === 'video') {

        let sendParameter = sender.getParameters();

        sendParameter.mirrorMode = ToRtcMirrorMode(mode);

        await sender.setParameters(sendParameter);
      }
    });
  }

  offer(stream, mirror_mode,bandWidth,simulcastObj, data, handle) {

    if (stream) {

      this.localstream = stream;
      this._onRelpaceTrack = async (track, kind) => {
        if (kind === 'audio') {
          this.aTransceiver.sender.replaceTrack(track);
        } else if (kind === 'video') {
          this.vTransceiver.sender.replaceTrack(track);
        }
      };

      this.localstream.on('replaceTrack', this._onRelpaceTrack);

      this.localMediaStream = new MediaStream();
      this.aTransceiver = this.pc.addTransceiver(stream.audioTrack || 'audio', {
        streams: [this.localMediaStream]
      });
      let simulcastLayers = simulcastObj.layer;
      if (simulcastLayers > 1 && simulcastLayers <= 3) {
        let simulcastBitrate = simulcastObj.bitrate;
        let simulcastWidth = simulcastObj.width;
        let resolution = stream.videoResolution;
        let multiple = 2;
        if(resolution && resolution.width && simulcastWidth) {
          multiple = parseFloat(resolution.width/simulcastWidth).toFixed(2);
          if(multiple <= 1 || multiple > 100) {
            multiple = 2;
          }
        }
        let normalizedEncodings = [];
        let scalDownBys = [1.0,2.0,4.0];
        let maxBitrates = [5000000,1000000,500000];
        maxBitrates[0] = bandWidth*1000;
        if(simulcastLayers == 2) {
          maxBitrates[simulcastLayers - 1] = simulcastBitrate*1000;
          scalDownBys[simulcastLayers - 1] = multiple;
        }
        for (let i = 0;  i < simulcastLayers; i++) {
            if(i == 1 )
            {
              normalizedEncodings.push({
                active: true,
                scaleResolutionDownBy: scalDownBys[i],
                maxBitrate: maxBitrates[i],
                scalabilityMode: "S1T1",
                rid: `r${i}`
              });
            } else {
              normalizedEncodings.push({
                active: true,
                scaleResolutionDownBy: scalDownBys[i],
                maxBitrate: maxBitrates[i],
                scalabilityMode: "S1T1",
                rid: `r${i}`
              });
            }
        }
        //normalizedEncodings.reverse();
        this.vTransceiver = this.pc.addTransceiver(stream.videoTrack || 'video', {
            streams: [this.localMediaStream],
            sendEncodings: normalizedEncodings,
        });

      } else  {
        this.vTransceiver = this.pc.addTransceiver(stream.videoTrack || 'video', {
            streams: [this.localMediaStream],
        });
      }
    }

    if (data) {
      for (let d in data) {
        if (data.hasOwnProperty(d)) {
          let eventHandles = data[d];
          let dc = this.pc.createDataChannel(d);
          this.sChannel[d] = new Channel(dc, this.id);

          for (let e in eventHandles) {
            if (eventHandles.hasOwnProperty(e)) {
              this.sChannel[d][e] = eventHandles[e]
            }
          }
        }
      }
    }
    let senders = this.pc.getSenders();
    console.log(senders);
    return new Promise((y, n) => {
      this.pc.createOffer(this.offerConstraint).then(sdp => {
        let _sdp = JSON.parse(JSON.stringify(sdp));
        if (typeof handle === 'function') {
          _sdp = handle(_sdp);
        }

        this.pc.setLocalDescription(_sdp).then(_ => {

          this.pc.getSenders().forEach(async sender => {

            if (sender.track && sender.track.kind === 'video') {

              let sendParameter = sender.getParameters();

              // do not limit the bandwidth.
              // if (sendParameter.encodings[0]) {

                //default max bps: see webrtc_video_engine.cc, GetMaxDefaultVideoBitrateKbps()
                // sendParameter.encodings[0].maxBitrate = 5 * 1024 * 1024; //80 << 20;

                //default is 60
                //sendParameter.encodings[0].maxFramerate = 30;
              // }

              // default is balanced.
              // Notice: use |maintain-framerate| will cause screen share resolution was been decreased on Chrome 83 or later
              // sendParameter.degradationPreference = "maintain-framerate";

              sendParameter.mirrorMode = ToRtcMirrorMode(mirror_mode);
              await sender.setParameters(sendParameter);

            } else if (sender.track && sender.track.kind === 'audio') {

              // let sendParameter = sender.getParameters();
              // sendParameter.encodings[0].networkPriority = 'high';
              // await sender.setParameters(sendParameter);
            }
          });

          y(_sdp);

        }).catch(n)
      }).catch(n)
    })
  }

  answer(rsdp, stream, data, handle) {

    if (stream) {

      this.localstream = stream;

      this._onRelpaceTrack = async (track, kind) => {
        if (kind === 'audio') {
          this.aTransceiver.sender.replaceTrack(track);
        } else if (kind === 'video') {
          this.vTransceiver.sender.replaceTrack(track);
        }
      };

      this.localstream.on('replaceTrack', this._onRelpaceTrack);

      this.localMediaStream = new MediaStream();

      this.aTransceiver = this.pc.addTransceiver(stream.audioTrack || 'audio', {
        streams: [this.localMediaStream]
      });

      this.vTransceiver = this.pc.addTransceiver(stream.videoTrack || 'video', {
        streams: [this.localMediaStream]
      });
    }

    if (data) {
      this.pc.ondatachannel = event => {
        if (event.channel) {
          let cLabel = event.channel.label;
          this.rChannel[cLabel] = new Channel(event.channel, this.id);
          if (data[cLabel]) {
            for (let e in data[cLabel]) {
              if (data[cLabel].hasOwnProperty(e)) {
                this.rChannel[cLabel][e] = data[cLabel][e]
              }
            }
          }
        }
      }
    }

    return new Promise((y, n) => {
      this.pc.setRemoteDescription(rsdp).then(_ => {
        this.pc.createAnswer().then(sdp => {
          let _sdp = JSON.parse(JSON.stringify(sdp));
          if (typeof handle === 'function') {
            _sdp = handle(_sdp)
          }
          this.pc.setLocalDescription(_sdp).then(_ => {
            y(_sdp)
          }).catch(n)
        }).catch(n)
      }).catch(n)
    })
  }

  async foffer(sdp, handle) {
    if (this.isSendNegoIce) {
      this.sendNegoIce = true;

      while (true) {
        if (this.iceQueue.length == 0) break;
        let ice = this.iceQueue.shift();
        this.onice(ice);
      }
    }

    let _sdp = JSON.parse(JSON.stringify(sdp))
    if (typeof handle === 'function') {
      _sdp = handle(_sdp)
    }
    await this.pc.setRemoteDescription(_sdp)

    if (this.isRecvNegoIce) {
      this.recvNegoIce = true;

      while (true) {
        if (this.recvIceQueue.length == 0) break;
        let ice = this.recvIceQueue.shift();
        this.pc.addIceCandidate(ice);
      }
    }

  }

  getStats(success, error) {
    if (this.pc) {
      if (success) {
        this.pc.getStats(success, error)
      } else {
        return this.pc.getStats(null)
      }
    }
  }

  async restartIce(sdp) {
    if (!sdp) { // as an offer.
      const offer = await this.pc.createOffer({
        iceRestart: true
      });
      await this.pc.setLocalDescription(offer);

    } else { // as an answer
      // @todo(zsf)
      await this.pc.setRemoteDescription(sdp);
    }
  }

  close() {
    if (this._onRelpaceTrack) {
      this.localstream.removeListener('replaceTrack', this._onRelpaceTrack);
      this._onRelpaceTrack = null;
    }

    if (this.pc !== null) {
      this.pc.onicecandidate = null;
      this.pc.ontrack = null;
      this.pc.oniceconnectionstatechange = null;
      this.pc.onnegotiationneeded = null;
      this.pc.onsignalingstatechange = null;
      this.pc.ondatachannel = null;
      this.pc.close();
      this.pc = null;
    }
  }
}

Peer.MirrorMode = {
  NoMirror: 0,
  HorizonMirror: 1,
  VerticalMirror: 2,
  HorizonVerticalMirror: 3
}

module.exports = Peer
