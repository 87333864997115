const PackageData = require('./package_data');
class FrameDelayRate {
	construct(options,type) {
		this.win = 6;
		this.type = type;
    if(options.lags && options.lags.win >1 && options.lags.win <= 100) {
			this.win = options.lags.win;
    }
    this.videoFrameDelay = 600;
    if(options.lags && options.lags.videoFrameDelay > 1 && options.lags.videoFrameDelay < 1000) {
      this.videoFrameDelay = options.lags.videoFrameDelay;
    }
    this.queue = [];
		this.delayInvalidCount = 0;
		this.delayOverCount = 0;
		console.log("win:%d-videoFrameDelay:%d",this.win,this.videoFrameDelay);
	}

	addFrameDelay(framDelay) {
		if(framDelay == -1)
		{
			this.delayInvalidCount++;
			this.delayOverCount = 0;
			this.queue = [];
		}
		else if(framDelay > this.videoFrameDelay)
		{
			this.delayInvalidCount = 0;
			this.delayOverCount++;
			if(this.queue.length >= this.win)
			{
				this.queue.shift();
			}
			this.queue.push(framDelay);
		}
		else
		{
			this.delayInvalidCount = 0;
			this.delayOverCount = 0;
			this.queue = [];
		}
	}

	judgeLags() {
		let overLoad = false;
		let avg = 0;
		if(this.delayOverCount >= this.win) {
			let sum = 0;
			for(let i=0;i<this.queue.length;i++)
			{
				sum += parseInt(this.queue[i]);
			}
			avg = sum/this.queue.length;
			overLoad = true;

		} else if(this.delayInvalidCount >= this.win) {
			overLoad = true;
			avg = -1;
		}
		return PackageData(overLoad,avg.toFixed(2));
	}

}

module.exports = FrameDelayRate