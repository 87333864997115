const Queue = require('./queue');

function beforeSyncTask() {
  // empty
  // console.log('beforeSyncTask');
}

function afterSyncTask() {
  // empty
  // console.log('afterSyncTask');
}

/**
 * ---              ---  q1
 * ---              ---  q2
 * ---  > (sync) >  ---  q3
 * ---              ---  q4
 * ---              ---  q5
 * ...              ...
 */
class CommandQueues {

  constructor() {
    this._syncQueue = new Queue();
    this._syncQueue.id = 'sync-queue';
    this._queues = new Map();
  }

  async push({
    queueId = null,
    task
  }) {
    if (queueId) {
      let queue = this._queues.get(queueId);
      if (!queue) {
        queue = new Queue();
        queue.id = queueId;
        this._queues.set(queueId, queue);
        queue.on('empty', (id) => {
          this._queues.delete(id);
        });
      }

      return queue.push(task);

    } else {
      // sync 
      const promises = [];
      this._queues.forEach(queue => {
        promises.push(queue.push(beforeSyncTask));
      });

      const taskWrapper = async () => {
        if (promises.length > 0) {
          await Promise.all(promises)
        }
        return task();
      }

      const promise = this._syncQueue.push(taskWrapper);

      this._queues.forEach(queue => {
        promises.push(queue.push(() => {
          return promise.then(afterSyncTask);
        }));
      });

      return promise;
    }
  }

  clear({
    error = undefined,
    queueId = undefined
  } = {}) {

    for (const [id, q] of this._queues) {
      if (!queueId || id === queueId) {
        q.clear(error);
      }
    }

    if (!queueId) {
      this._syncQueue.clear(error);
    }
  }

}


module.exports = CommandQueues;
