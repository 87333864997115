const RTCatLog = require('../../utils/log')

/**
 * wrap WebSocket
 * WebSocket : https://developer.mozilla.org/en-US/docs/Web/API/WebSocket
 */
class Connector {
  constructor(url, protocol = 'rtcat-protocol') {
    this.url = url
    this.tag = `Connector : ${this.url}`

    this.protocol = protocol
    this.socket = null

    this.state = Connector.CLOSED
    //event
    this.onmessage = m => RTCatLog.V(this.tag, '<onmessage>', m);
    this.onclose = _ => RTCatLog.I(this.tag, '<onclose>');
    this.onopen = _ => RTCatLog.I(this.tag, '<onopen>');
    this.onerror = e => RTCatLog.E(this.tag, '<onerror>', e);
  }

  connect() {
    if (this.protocol) {
      this.socket = new WebSocket(this.url, this.protocol)
    } else {
      this.socket = new WebSocket(this.url)
    }
    this.state = Connector.CONNECTING;

    this.socket.onopen = () => {
      this.state = Connector.OPEN;
      this.onopen()
    };

    this.socket.onmessage = event => {
      let message;

      try {
        message = JSON.parse(event.data);
      } catch (e) {
        message = event.data
      }

      this.onmessage(message)

    };

    this.socket.onclose = event => {
      //todo ?

      // console.log(event);
      this.close();
      this.onclose(event);

      this.state = Connector.CLOSED
    }

    this.socket.onerror = event => {
      this.onerror(event)
    }
  }

  sendJSONWithHandler(json, handle) {
    if (typeof json == 'object') {
      let message = JSON.stringify(json)
      if (handle && (typeof handle == 'function')) {
        message = handle(message)
      }
      this.sendStr(message)
    } else {
      RTCatLog.W(this.tag, '<sendJSONWithHandler>', `${typeof json} is not supported by the function`)
    }
  }

  sendStr(str) {
    if (this.socket && (this.socket.readyState == 1)) {
      RTCatLog.V(this.tag, '<sendStr>', str)
      this.socket.send(str)
    } else {
      RTCatLog.W(this.tag, '<sendStr>', 'pls use connector when it is open')
    }
  }

  close() {
    this.state = Connector.CLOSING
    if (this.socket) {
      if (this.socket.readyState === 1) {
        this.socket.close()
      }
      this.socket = null
    }
  }
}

Connector.CONNECTING = 0 //The connection is not yet open.
Connector.OPEN = 1 //The connection is open and ready to communicate.
Connector.CLOSING = 2 //The connection is in the process of closing.
Connector.CLOSED = 3 //The connection is closed or couldn't be opened.

module.exports = Connector
