const RTCatLog = require('../../utils/log');
const Connector = require('./connector')


class LogServer {

  constructor(tokenId, url) {

    this.tokenId = tokenId;
    this.url = url;

    this.onerror = () => {
      //todo(cc): maybe add a warning
    };

    this.ondisconnected = () => {};
    this.onconnnected = () => {};

    this.connector = null;
    this.queue = [];
  }

  connect() {
    const queryUrl = this.createWssUrl(this.url);

    this.connector = new Connector(queryUrl, null);

    this.connector.onopen = _ => {
      RTCatLog.I('Log connection open');
      this.releaseQ()
      this.onconnnected()
    }

    this.connector.onclose = _ => {
      RTCatLog.I('Log connection close')
      this.ondisconnected();
    };

    this.connector.onerror = e => {
      //todo(cc):
      RTCatLog.E(e)
      this.onerror(e);
    }

    this.connector.connect()
  }

  close() {
    if (this.connector) {
      this.connector.close()
    }
  }

  releaseQ() {
    while (this.queue.length > 0) {

      let log = this.queue.shift()

      let {
        type,
        sessionId,
        userId,
        feedId,
        data
      } = log

      this.sendLog(type, sessionId, userId, feedId, data)
    }
  }

  /**
   * type:
   * dynamic, < dynamic webrtc stats >
   * event,   < client side event >
   * api < client side api call >
   * sig-up   < signal up >
   * sig-dw   < signal down >
   */
  sendLog(type, sessionId, userId, feedId, data) {

    if (this.connector.state === Connector.OPEN) {

      let logMsg = {
        type,
        sessionId,
        userId,

        data
      }

      if (feedId) {
        logMsg.feedId = feedId
      }

      logMsg.timestamp = Date.now() / 1000

      this.connector.sendJSONWithHandler(logMsg)

    } else if (this.connector.state === Connector.CONNECTING) {

      this.queue.push({
        type,
        sessionId,
        userId,
        feedId,
        data
      });

    }
  }

  createWssUrl(url) {
    return `${url}?tokenId=${this.tokenId}`
  }

}

module.exports = LogServer
