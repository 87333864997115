class Utils {

  static uuid() {
    return Utils.generateUUID();
  }

  static generateUUID() {
    let d = new Date().getTime()
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = (d + Math.random() * 16) % 16 | 0
      d = Math.floor(d / 16)
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16)
    })
  }

  static getAudioContext() {
    if (Utils.audioContext) {
      return Utils.audioContext
    }

    let AudioContext = window.AudioContext || window.webkitAudioContext
    Utils.audioContext = new AudioContext()
    return Utils.audioContext
  }

  static createAudioMeter(audioContext, clipLevel, averaging, clipLag) {
    const processor = audioContext.createScriptProcessor(512)

    processor.onaudioprocess = (event) => {
      const buf = event.inputBuffer.getChannelData(0)
      const bufLength = buf.length
      let sum = 0
      let x

      // Do a root-mean-square on the samples: sum up the squares...
      for (let i = 0; i < bufLength; i++) {
        x = buf[i]
        if (Math.abs(x) >= processor.clipLevel) {
          processor.clipping = true
          processor.lastClip = window.performance.now()
        }
        sum += x * x
      }

      // ... then take the square root of the sum.
      const rms = Math.sqrt(sum / bufLength)

      // Now smooth this out with the averaging factor applied
      // to the previous sample - take the max here because we
      // want "fast attack, slow release."
      processor.volume = Math.max(rms, processor.volume * processor.averaging)
    }

    processor.clipping = false
    processor.lastClip = 0
    processor.volume = 0
    processor.clipLevel = clipLevel || 0.98
    processor.averaging = averaging || 0.95
    processor.clipLag = clipLag || 750

    // this will have no effect, since we don't copy the input to the output,
    // but works around a current Chrome bug.
    processor.connect(audioContext.destination)

    processor.checkClipping =
      () => {
        if (!processor.clipping)
          return false
        if ((processor.lastClip + processor.clipLag) < window.performance.now())
          processor.clipping = false
        return processor.clipping
      }

    processor.shutdown =
      () => {
        processor.disconnect()
        processor.onaudioprocess = null
        // audioContext.close();
      }

    return processor
  }

  static mapToJSON(map) {
    let object = {};
    for (let [key, value] of map) {
      object[key] = value;
    }
    return object;
  }

}


module.exports = Utils
