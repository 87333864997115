function unpublish(params) {

  const sid = params._sid;
  const cid = params._cid;

  const sessionUser = this.getSessionUser(sid, cid);
  if (sessionUser) {
    sessionUser.emit('#unpublish', params.args);
  }
}


module.exports = unpublish;
