class Detect {
  static browser() {
    const ua_string = navigator.userAgent;

    function chromeBrowser() {
      let result = {
        name: null,
        version: 0,
        chromeVersion: 0
      };
      let browserArray;

      const qq_re = new RegExp("Chrome/([0-9]+).+QQBrowser/([0-9.]+)", "g");
      const baidu_re = new RegExp("Chrome/([0-9]+).+BIDUBrowser/([0-9.]+)", "g");
      const maxthon_re = new RegExp("Chrome/([0-9]+).+Maxthon/([0-9.]+)", "g");
      const lb_re = new RegExp("Chrome/([0-9]+).+LBBROWSER", "g");
      const sogou_re = new RegExp("Chrome/([0-9]+).+SE.+MetaSr");
      const chrome_re = new RegExp("Chrome/([0-9.]+)");
      const se_360_re = new RegExp("Chrome/([0-9]+).+360SE");
      const ee_360_re = new RegExp("Chrome/([0-9]+).+360EE");
      const explorer_2345 = new RegExp("Chrome/([0-9]+).+2345Explorer/([0-9.]+)");

      if (browserArray = explorer_2345.exec(ua_string)) {
        result.name = '2345';
        result.chromeVersion = browserArray[1];
        result.version = browserArray[2];
      } else if (browserArray = qq_re.exec(ua_string)) {
        result.name = 'QQ';
        result.chromeVersion = browserArray[1];
        result.version = browserArray[2];
      } else if (browserArray = baidu_re.exec(ua_string)) {
        result.name = 'Baidu';
        result.chromeVersion = browserArray[1];
        result.version = browserArray[2];
      } else if (browserArray = maxthon_re.exec(ua_string)) {
        result.name = 'Maxthon';
        result.chromeVersion = browserArray[1];
        result.version = browserArray[2];
      } else if (browserArray = lb_re.exec(ua_string)) {
        result.name = 'LB';
        result.chromeVersion = browserArray[1];
        result.version = 0;
      } else if (browserArray = sogou_re.exec(ua_string)) {
        result.name = 'Sougo';
        result.chromeVersion = browserArray[1];
        result.version = 0;
      } else if (browserArray = se_360_re.exec(ua_string)) {
        result.name = '360SE';
        result.chromeVersion = browserArray[1];
        result.version = 0;
      } else if (browserArray = ee_360_re.exec(ua_string)) {
        result.name = '360EE';
        result.chromeVersion = browserArray[1];
        result.version = 0;
      } else if (browserArray = chrome_re.exec(ua_string)) {
        result.name = 'Chrome';
        result.chromeVersion = browserArray[1];
        result.version = browserArray[1];
      }

      return result
    }

    const edge_re = new RegExp("Edge/([0-9.]+)", "g");
    const firefox_re = new RegExp("Firefox/([0-9.]+)", "g");
    const chrome_kind_re = new RegExp("Chrome/([0-9.]+)", "g");
    const ie_re = new RegExp("\.NET.+rv:([0-9.]+)", "g");
    const safari_re = new RegExp("Version/([0-9.]+).+Safari", "g");
    let browserArray;
    let result = {
      name: "",
      version: 0,
      chromeVersion: 0,
    };

    if (browserArray = edge_re.exec(ua_string)) {
      result.name = 'Edge';
      result.version = browserArray[1];
    } else if (browserArray = firefox_re.exec(ua_string)) {
      result.name = 'Firefox';
      result.version = browserArray[1];
    } else if (chrome_kind_re.test(ua_string)) {
      result = chromeBrowser();
    } else if (browserArray = ie_re.exec(ua_string)) {
      result.name = 'IE';
      result.version = browserArray[1];
    } else if (browserArray = safari_re.exec(ua_string)) {
      result.name = 'Safari';
      result.version = browserArray[1];
    }

    function parseVersion(version) {
      let stringVersion = version + "";
      let ns = stringVersion.split(".");
      let rs = "";
      for (let i = 0; i < ns.length; i++) {
        if (i == 0) {
          rs += ns[0] + ".";
        } else {
          rs += ns[i];
        }
      }

      return Number(rs);
    }

    return {
      name: result.name,
      version: parseVersion(result.version),
      chromeVersion: parseVersion(result.chromeVersion)
    }

  }

  static compatibility(browser) {
    let x;

    switch (browser.name) {
      case "Chrome":
        x = {
          media: true,
          data: true,
          screen: true
        };
        break;
      case "QQ":
        x = {
          media: true,
          data: true,
          screen: true
        };
        break;
      case "Baidu":
        x = {
          media: false,
          data: true,
          screen: false
        };
        break;
      case "Sougou":
        x = {
          media: false,
          data: true,
          screen: false
        };
        break;
      case "LB":
        x = {
          media: false,
          data: false,
          screen: false
        };
        break;
      case "Firefox":
        x = {
          media: true,
          data: true,
          screen: true
        };
        break;
      case 'Safari':
        if (browser.version >= 11) {
          x = {
            media: true,
            data: true,
            screen: false
          }
        } else {
          x = {
            media: false,
            data: false,
            screen: false
          }
        }
        break;

      case '2345':
        x = {
          media: true,
          data: true,
          screen: true
        };
        break;
      case '360SE':
        x = {
          media: true,
          data: true,
          screen: true
        };
        break;
      case '360EE':
        x = {
          media: true,
          data: true,
          screen: true
        };
        break;
      default:
        x = {
          media: false,
          data: false,
          screen: false
        };
        break;

    }

    //chrome 版本号小于 50 的不支持
    if (browser.chromeVersion > 0 && browser.chromeVersion < 50) {
      x.media = false;
    }

    return x;
  }

  static all(options, callback) {
    let browser;
    let compatibility;

    let isDetectBrowser = true;
    let isDetectCompatibility = true;


    if ((options == null) && (callback == null)) {
      isDetectBrowser = true;
      isDetectCompatibility = true;
      callback = function () {
      };

    } else if ((options != null) && (callback != null)) {
      isDetectBrowser = options.browser;
      isDetectCompatibility = options.compatibility;

    } else if (typeof options === "object") {
      isDetectBrowser = options.browser;
      isDetectCompatibility = options.compatibility;
      callback = function () {
      };

    } else if (typeof options === "function") {
      isDetectBrowser = true;
      isDetectCompatibility = true;
      callback = options;
    }


    let detectResult = {};
    return new Promise((resolve, reject) => {
      browser = Detect.browser();
      if (isDetectCompatibility) {
        compatibility = Detect.compatibility(browser);
        if (compatibility.media == false) {
          const error = "This Browser is not supported by shishimao";
          callback(error, null);
          reject(error);
        }
      }
      if (isDetectBrowser) detectResult.browser = browser;
      if (isDetectCompatibility) detectResult.compatibility = compatibility;

    })
  }

  static isMoz() {
    return !!navigator.mozGetUserMedia;
  }

  static isSafari() {
    return (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1);
  }

  static getClientType() {
    return this.isMoz() ? 'Firefox' : 'Chrome';
  }

  static getPlatform() {
    let browser = this.browser();
    return browser.name + " " + browser.version;
  }

  static getDevice() {
    let os = [
      {name: 'Windows Phone', value: 'Windows Phone', version: 'OS'},
      {name: 'Windows', value: 'Win', version: 'NT'},
      {name: 'iPhone', value: 'iPhone', version: 'OS'},
      {name: 'iPad', value: 'iPad', version: 'OS'},
      {name: 'Kindle', value: 'Silk', version: 'Silk'},
      {name: 'Android', value: 'Android', version: 'Android'},
      {name: 'PlayBook', value: 'PlayBook', version: 'OS'},
      {name: 'BlackBerry', value: 'BlackBerry', version: '/'},
      {name: 'Macintosh', value: 'Mac', version: 'OS X'},
      {name: 'Linux', value: 'Linux', version: 'rv'},
      {name: 'Palm', value: 'Palm', version: 'PalmOS'}
    ];

    const header = [
      navigator.platform,
      navigator.userAgent,
      navigator.appVersion,
      navigator.vendor,
      window.opera
    ];

    function matchItem(string, data) {
      let i = 0,
        j = 0,
        html = '',
        regex,
        regexv,
        match,
        matches,
        version;

      for (i = 0; i < data.length; i += 1) {
        regex = new RegExp(data[i].value, 'i');
        match = regex.test(string);
        if (match) {
          regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
          matches = string.match(regexv);
          version = '';
          if (matches) {
            if (matches[1]) {
              matches = matches[1];
            }
          }
          if (matches) {
            matches = matches.split(/[._]+/);
            for (j = 0; j < matches.length; j += 1) {
              if (j === 0) {
                version += matches[j] + '.';
              } else {
                version += matches[j];
              }
            }
          } else {
            version = '0';
          }
          return {
            name: data[i].name,
            version: parseFloat(version)
          };
        }
      }
      return {name: 'unknown', version: 0};
    }

    const agent = header.join(' ');
    const rv = matchItem(agent, os);
    return rv.name + ' ' + rv.version;
  }

  static getDomain() {
    return window.location.hostname;
  }

  static all (options) {
    let browser
    let detectResult = {}

    let isDetectDevice = true
    let isDetectBrowser = true
    let isDetectCompatibility = true
    let isDetectNetwork = false

    if (options != null && typeof options === 'object') {
      isDetectDevice = options.device
      isDetectBrowser = options.browser
      isDetectCompatibility = options.compatibility
      isDetectNetwork = options.network
    }
    return new Promise((resolve, reject) => {
      browser = Detect.browser()
      if (isDetectBrowser) detectResult.browser = browser
      if (isDetectCompatibility) detectResult.compatibility = Detect.compatibility(browser)
      if (isDetectDevice) detectResult.device = Detect.device()
      if (isDetectNetwork) {
        Detect.net().then(function (result) {
          detectResult.network = {
            download: result[0],
            upload: result[1]
          }
          resolve(detectResult)
        })
      } else {
        resolve(detectResult)
      }
    })
  }

}

module.exports = Detect
