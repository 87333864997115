const PackageData = require('./package_data');
class FrameAverage {
  constructor(options,type) {
    this.win = 6;
    this.type = type;
    if(options.lags && options.lags.win >1 && options.lags.win <= 100) {
      this.win = options.lags.win
    }
    let pubFrameRate = 5;
    if(options.lags && options.lags.pubFps > 1 && options.lags.pubFps < 100)
    {
      pubFrameRate = options.lags.pubFps;
    }
    let subFrameRate = 5;
    if(options.lags && options.lags.subFps > 1 && options.lags.subFps < 100)
    {
      subFrameRate = options.lags.subFps;
    }
    this.threshold = new Map();
    this.threshold.set("publisher",pubFrameRate);
    this.threshold.set("subscriber",subFrameRate);
    this.queue = [];
    console.log("win:%d-pubFrameRate:%d-subFrameRate:%d",this.win,pubFrameRate,subFrameRate);
  }

  addSecondFps(fps) {
      if(this.queue.length >= this.win)
      {
          this.queue.shift();
      }
      this.queue.push(fps);
  }

  judgeLags(type) {
      let overLoad = false;
      let avg = 0;
      let value = this.threshold.get(type);
      let sum = 0;
      for(let i=0;i<this.queue.length;i++)
      {
          sum += parseInt(this.queue[i]);
      }
      avg = sum/this.queue.length;
      if(avg < value) {
        overLoad = true;
      }
      return PackageData(overLoad,avg.toFixed(2));
  }
}
module.exports = FrameAverage