const PackageData = require('./package_data');
class PacketLoss {
  constructor(options,type,mediaType) {
    this.win = 6;
    this.type = type;
    this.media = mediaType;
    if(options.lags && options.lags.win >1 && options.lags.win <= 100)
    {
      this.win = options.lags.win
    }
    this.threshold = new Map();
    let videoLossRate = 25;
    if(options.lags && options.lags.videoLossRate > 1 && options.lags.videoLossRate < 100)
    {
      videoLossRate = options.lags.videoLossRate;
    }
    let audioLossRate = 30;
    if(options.lags && options.lags.audioLossRate > 1 && options.lags.audioLossRate < 100)
    {
      audioLossRate = options.lags.audioLossRate;
    }
    this.threshold.set("video",videoLossRate);
    this.threshold.set("audio",audioLossRate);
    this.queue = [];
    this.plQueue = [];
    console.log("win:%d-videoLossRate:%d-audioLossRate:%d",this.win,videoLossRate,audioLossRate);
  }

  addData(packets, packetsLost) {

    if(packets>1000000)
    {
      console.log("too large number %s lost:%s",packets,packetsLost);
    }
    if(this.plQueue.length < 3) {
      this.plQueue.push([Number(packets),Number(packetsLost)]);
      return;
    } else {
      this.plQueue.shift();
      this.plQueue.push([Number(packets),Number(packetsLost)]);
    }
    let lossRate = 0;
    let rec = Math.abs(this.plQueue[this.plQueue.length-1][0] - this.plQueue[0][0]);
    let lost = Math.abs(this.plQueue[this.plQueue.length-1][1] - this.plQueue[0][1]);
    if(rec != 0) {
      lossRate = Number(lost)/(Number(rec)+Number(lost));
    }
    while (this.queue.length >= this.win) {
      this.queue.shift()
    }
    let percentLossRate = (lossRate * 100).toFixed(2);
    this.queue.push(percentLossRate);
  }

  getPacketLossRate() {
    let length = this.queue.length
    let result = 0;

    if(length < this.win) {
      return result;
    }
    let sum = 0;
    for(let i=0;i<this.queue.length;i++)
    {
        sum += parseFloat(this.queue[i]);
    }
    result = sum/length;
    if (result >= 100) {
      result = 100;
    }
    return result.toFixed(2);
  }

  judgeLags(lossRate,type) {
    //every two seconds emit once
    let overLoad = false
    let rate = this.threshold.get(type);
    if(lossRate > rate) {
      overLoad = true;
    }
    return PackageData(overLoad,lossRate);
  }
}

module.exports = PacketLoss
